/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const NewLogo = ({
  type,
  size,
  className,
  logo = "/img/logo-22.png",
  img = "/img/logo-24.png",
  logo1 = "/img/logo-30.png",
  logo2 = "/img/logo-31.png",
  logo3 = "/img/logo-32.png",
  logo4 = "/img/logo-33.png",
  logo5 = "/img/logo-34.png",
}) => {
  return (
    <div className={`new-logo ${className}`}>
      <img
        className={`logo ${size}`}
        alt="Logo"
        src={
          size === "mobile" && ["caribs", "timesharing-rent"].includes(type)
            ? img
            : type === "lottery" && size === "mobile"
            ? "/img/logo-25.png"
            : type === "marketplace" && size === "mobile"
            ? "/img/logo-26.png"
            : type === "academy" && size === "mobile"
            ? "/img/logo-27.png"
            : type === "defi" && size === "mobile"
            ? "/img/logo-28.png"
            : type === "tokenization" && size === "mobile"
            ? "/img/logo-29.png"
            : type === "lottery" && size === "desktop"
            ? logo1
            : type === "marketplace" && size === "desktop"
            ? logo2
            : type === "academy" && size === "desktop"
            ? logo3
            : type === "defi" && size === "desktop"
            ? logo4
            : type === "tokenization" && size === "desktop"
            ? logo5
            : logo
        }
      />
      <div className={`rent-onchain size-${size}`}>
        {type === "timesharing-rent" && <p className="text-wrapper-70">RENT ONCHAIN | TIMESHARING + RENT</p>}

        {type === "caribs" && <>RENT ONCHAIN |&nbsp;&nbsp;CARIBBEAN</>}

        {type === "lottery" && <>RENT ONCHAIN | RAFFLE</>}

        {type === "marketplace" && <>RENT ONCHAIN | MARKETPLACE</>}

        {type === "academy" && <>RENT ONCHAIN | ACADEMY</>}

        {type === "defi" && <>RENT ONCHAIN | DEFI</>}

        {type === "tokenization" && <>RENT ONCHAIN | TOKENIZATION</>}
      </div>
    </div>
  );
};

NewLogo.propTypes = {
  type: PropTypes.oneOf(["defi", "academy", "marketplace", "timesharing-rent", "caribs", "lottery", "tokenization"]),
  size: PropTypes.oneOf(["desktop", "mobile"]),
  logo: PropTypes.string,
  img: PropTypes.string,
  logo1: PropTypes.string,
  logo2: PropTypes.string,
  logo3: PropTypes.string,
  logo4: PropTypes.string,
  logo5: PropTypes.string,
};
