/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Umbrella = ({ className }) => {
  return (
    <svg
      className={`umbrella ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 25 24"
      width="25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M13.1234 12.6751L10.7004 16.8721M17.5179 3.91274C15.4656 5.16498 11.3146 8.44036 11.1295 11.524M18.5148 4.48829C18.502 6.91805 17.8182 12.1952 15.1857 13.8658M2.05078 19.1897L4.58423 18.0752C6.13228 17.3941 7.83796 17.1525 9.51425 17.3767L9.59183 17.3871C11.2939 17.6148 12.8984 18.3139 14.2242 19.4053L16.2507 21.0736M6.91699 9.09184L19.3299 16.2584C20.0319 16.6638 20.9297 16.4232 21.335 15.7212C23.6319 11.7428 22.2688 6.65568 18.2905 4.35877L17.7421 4.04218C13.7637 1.74527 8.67663 3.10835 6.37972 7.08672C5.97438 7.78878 6.21493 8.68651 6.91699 9.09184Z"
        stroke="#6957DA"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};
