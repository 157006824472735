import PropTypes from "prop-types";
import React from "react";
import { Form } from "../Form";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
import "./style.css";
import i18n from "i18next";
import { Links } from "../Links";

export const FooterLandingPage = ({ language, backendCall }) => {
  const { t } = useTranslation("global");

  const socialLinks = {
    Telegram:
      i18n.language === "es"
        ? "https://t.me/rentonchainchannelesp"
        : "https://t.me/rentonchainchanneleng",
    Instagram:
      i18n.language === "es"
        ? "https://www.instagram.com/rentonchain_esp/"
        : "https://www.instagram.com/rentonchain/",
    Twitter: "https://twitter.com/TheRentOnChain",
    Youtube: "https://www.youtube.com/@RentOnchain",
    Facebook: "https://www.facebook.com/profile.php?id=100092581430037",
  };

  return (
    <div className="footer-landing-page">
      <div className="footer-main-container">
        <div className="frame-23">
          <div className="div-8">{t("footer.contact.title")}</div>
          <div className="frame-24">
            <div className="frame-22">
              <div className="div-10">{t("footer.contact.office")}</div>
              <div className="div-11">
                <p className="text-wrapper-70">{t("footer.contact.address")}</p>
              </div>
            </div>
            <div className="frame-22">
              <div className="div-10">
                {t("footer.contact.social_networks")}
              </div>
              <div className="div-9">
                <a target="_blank" href={socialLinks.Telegram}>
                  {t("footer.contact.telegram")}
                </a>
              </div>
              <div className="div-9">
                <a target="_blank" href={socialLinks.Instagram}>
                  {t("footer.contact.instagram")}
                </a>
              </div>
              <div className="div-9">
                <a target="_blank" href={socialLinks.Twitter}>
                  X(Twitter)
                </a>
              </div>
              <div className="div-9">
                <a target="_blank" href={socialLinks.Youtube}>
                  Youtube
                </a>
              </div>
              <div className="div-9">
                <a target="_blank" href={socialLinks.Facebook}>
                  Facebook
                </a>
              </div>
            </div>
            <div className="frame-25">
              <div className="div-10">{t("footer.contact.email")}</div>
              <div className="div-11">
                <a href="mailto:sales@rentoncha.in">
                  {t("footer.contact.email_text")}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="frame-21">
          <div className="div-8">{t("footer.menu.title")}</div>
          <div className="frame-22">
            <div className="div-9">
              <Link to="Project"> {t("footer.menu.project")}</Link>
            </div>
            <div className="div-9">
              <Link to="Benefits"> {t("footer.menu.benefits")}</Link>
            </div>
            <div className="div-9">
              <Link to="Properties"> {t("footer.menu.properties")}</Link>
            </div>
            <div className="div-9">
              {" "}
              <Link to="Riviera">{t("footer.menu.riviera_maya")}</Link>
            </div>
            <div className="div-9">
              {" "}
              <Link to="video">
                <div className="div-3">{t("footer.menu.videos")}</div>
              </Link>
            </div>
            <div className="div-9">
              <Link to="Strengths">{t("footer.menu.strengths")}</Link>
            </div>
            <div className="div-9 invisible">
              <Link to="team">{t("header.team")}</Link>
            </div>
            <div className="div-9 invisible">FAQ</div>
            <div className="div-9">
              <Link to="presale">{t("footer.menu.pre_sale")}</Link>
            </div>
          </div>
        </div>

        <div className="frame-29">
          <div className={`SI-TE-INTERESA language-1-${language}`}>
            {t("footer.form_title")}
          </div>
          <div className="frame-30">
            <Form
              className="fourth"
              formId="landing-page-caribs-form5"
              backendCall={backendCall}
            >
              {t("form.footer_button")}
            </Form>
          </div>
        </div>
      </div>
      <div className="frame-26">
        <div className="frame-27">
          <div className="div-12">©2024 Rent Onchain</div>
          <div className="div-12 invisible">
            {language === "esp" && <>Términos y condiciones</>}
            {language === "eng" && <>Terms and conditions</>}
            {t("footer.terms")}
          </div>
        </div>
        <div className="frame-28 invisible">
          <Links
            className="instance-node-5"
            color="black"
            line={
              language === "eng" ? "/img/line-13-2.svg" : "/img/line-13.svg"
            }
            size="desktop-m"
            state="defolt"
            text={t("footer.privacy_policy")}
            weight="reg"
          />
          <Links
            className="instance-node-5"
            color="black"
            line={
              language === "eng" ? "/img/line-13-3.svg" : "/img/line-13-1.svg"
            }
            size="desktop-m"
            state="defolt"
            text={t("footer.cookie_policy")}
            weight="reg"
          />
        </div>
      </div>
    </div>
  );
};

FooterLandingPage.propTypes = {
  language: PropTypes.oneOf(["esp", "eng"]),
};
