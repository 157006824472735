import React, { useRef, useState } from "react";
import { Button } from "../../components/Button";
import { DiamondWrapper } from "../../components/DiamondWrapper";
import { EnvironmentSun } from "../../components/EnvironmentSun";
import { Form } from "../../components/Form";
import { Globe } from "../../components/Globe";

import { InterfaceChartLine } from "../../components/InterfaceChartLine";
import { InterfaceHeart } from "../../components/InterfaceHeart";
import { NavigationBuilding } from "../../components/NavigationBuilding";
import { NewLogo } from "../../components/NewLogo";
import { SmileyHappy } from "../../components/SmileyHappy";
import { Status } from "../../components/Status";
import { Timer } from "../../components/Timer";
import { Trophy } from "../../components/Trophy";
import { UmbrellaWrapper } from "../../components/UmbrellaWrapper";
import { WarningShieldCheck } from "../../components/WarningShieldCheck";
import { EnvironmentLeaf } from "../../icons/EnvironmentLeaf";
import { InterfaceHeart01 } from "../../icons/InterfaceHeart01";
import { NavigationCarAuto2 } from "../../icons/NavigationCarAuto2";
import { Umbrella } from "../../icons/Umbrella";
import { WarningShieldCheck1 } from "../../icons/WarningShieldCheck1";
import { ArrowChevronRight } from "../../icons/ArrowChevronRight";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";
import i18n from "i18next";
import LanguageBlock from "../../components/LanguageBlock/LanguageBlock";
import { SuccessMessage } from "../../components/SuccessMessage";
import { TeamBlock } from "../../components/TeamBlock/TeamBlock";
import { FaqBlock } from "../../components/FaqBlock/FaqBlock";

import "./style.css?v=1.0";
import { Carousel } from "../../components/Carousel/Carousel";

const teamList = [
  "developers",
  "design",
  "operation",
  "marketing",
  "compliance_development",
];

export const LandingPageMobile = () => {
  const { t } = useTranslation("global");
  const focusForm = useRef(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [teamBlockIsOpen, setTeamBlockIsOpen] = useState(true);
  const [faqOpen, setFaqOpen] = useState(false);

  const socialLinks = {
    Telegram:
      i18n.language === "es"
        ? "https://t.me/rentonchainchannelesp"
        : "https://t.me/rentonchainchanneleng",
    Instagram:
      i18n.language === "es"
        ? "https://www.instagram.com/rentonchain_esp/"
        : "https://www.instagram.com/rentonchain/",
    Twitter: "https://twitter.com/TheRentOnChain",
    Youtube: "https://www.youtube.com/@RentOnchain",
    Facebook: "https://www.facebook.com/profile.php?id=100092581430037",
  };

  const videoLinks = {
    TopPresentation:
      i18n.language === "es"
        ? "https://www.youtube.com/embed/aSYU3IKFbL4?si=YUZPc4tRECUEsucx"
        : "https://www.youtube.com/embed/90H5jstVYQ0?si=Vab_j8YyLbAFXzIx",
    ButtomLanding:
      i18n.language === "es"
        ? "https://www.youtube.com/embed/UYFSAQPcWUg?si=yszjN4_aEOh7Q_Ui"
        : "https://www.youtube.com/embed/UYFSAQPcWUg?si=yszjN4_aEOh7Q_Ui",
  };

  const handleFocusForm = () => {
    if (focusForm.current) {
      focusForm.current.focus();
    }
  };

  const handleBackendCall = (isOk, error) => {
    console.debug("receive handle backend call", isOk, error);
    setShowSuccess(true);
  };

  return (
    <div className="landing-page-mobile">
      {showSuccess && <SuccessMessage onClose={() => setShowSuccess(false)} />}
      <div className="menu-mobile">
        <NewLogo
          className="design-component-instance-node-3"
          img="/img/logo-44.png"
          size="mobile"
          type="caribs"
        />
        <LanguageBlock />
        <img className="burger invisible" alt="Burger" src="/img/burger.svg" />
      </div>
      <div className="frame-73">
        <div className="frame-74">
          <div className="div-14">
            <p className="text-wrapper-36">{t("main_heading")}</p>
            <p className="DISFRUTA-LA-VIDA-EN-2">
              <span className="text-wrapper-37">
                {t("header_card.title1")} <br />
              </span>
              <span className="text-wrapper-38">{t("header_card.title2")}</span>
            </p>
          </div>
          <div className="frame-75">
            <iframe
              width="100%"
              height="325"
              src={videoLinks.TopPresentation}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
          <Button
            buttonClassName="button-8"
            className="design-component-instance-node-3"
            handleFocus={handleFocusForm}
            color="violet"
            device="mobile"
            size="m"
            state="default"
            text={t("header_card.mobile_button")}
          />
        </div>
        <div className="frame-76">
          <div id="Project" className="text-wrapper-39">
            {t("slider.title")}
          </div>
          <div className="frame-77">
            <Carousel />
          </div>
          <div className="slider-2 invisible">
            <img
              className="unsplash-2"
              alt="Unsplash"
              src="/img/unsplash-loi7whn1wrq-3.png"
            />
            <img
              className="unsplash-3"
              alt="Unsplash"
              src="/img/unsplash-loi7whn1wrq-8.png"
            />
            <img
              className="unsplash-4"
              alt="Unsplash"
              src="/img/unsplash-loi7whn1wrq-4.png"
            />
            <img
              className="unsplash-5"
              alt="Unsplash"
              src="/img/unsplash-loi7whn1wrq-4.png"
            />
            <img
              className="unsplash-6"
              alt="Unsplash"
              src="/img/unsplash-loi7whn1wrq-4.png"
            />
            <img
              className="unsplash-7"
              alt="Unsplash"
              src="/img/unsplash-loi7whn1wrq-4.png"
            />
          </div>
          <div className="slider-3 invisible">
            <div className="ellipse-3" />
            <div className="ellipse-4" />
            <div className="ellipse-4" />
            <div className="ellipse-4" />
            <div className="ellipse-4" />
          </div>
        </div>
        <div className="frame-78">
          <p className="text-wrapper-39">{t("welcome.title")}</p>
          <div className="frame-77">
            <p className="text-wrapper-40">{t("welcome.subtitle")}</p>
            <p className="en-rent-onchain-tu-2">
              <span className="text-wrapper-42">{t("welcome.text1")}</span>
              <span className="text-wrapper-43">*</span>
              <span className="text-wrapper-42"> {t("welcome.text2")}</span>
            </p>
          </div>
          <div className="frame-79">
            <Form
              className="mobileForm"
              formId="landing-page-caribs-mobile-form1"
              backendCall={handleBackendCall}
            >
              {t("form.button")}
            </Form>
          </div>
        </div>
        <div id="Benefits" className="frame-83">
          <div className="text-wrapper-39">{t("benefits.title")}</div>
          <div className="card-2">
            <div className="frame-84">
              <div className="frame-85">
                <div className="text-wrapper-46">+</div>
                <p className="text-wrapper-47">{t("benefits.list.1")}</p>
              </div>
              <div className="frame-85">
                <div className="text-wrapper-46">+</div>
                <p className="div-15">
                  <span className="text-wrapper-48">
                    {t("benefits.list.2")}
                  </span>
                  <span className="text-wrapper-49">*</span>
                  <span className="text-wrapper-48">
                    {" "}
                    {t("benefits.list.3")}
                  </span>
                </p>
              </div>
              <div className="frame-85">
                <div className="text-wrapper-46">+</div>
                <p className="text-wrapper-47">{t("benefits.list.4")}</p>
              </div>
              <div className="frame-85">
                <div className="text-wrapper-46">+</div>
                <p className="text-wrapper-47">{t("benefits.list.5")}</p>
              </div>
              <div className="frame-85">
                <div className="text-wrapper-46">+</div>
                <p className="div-15">
                  <span className="text-wrapper-48">
                    {t("benefits.list.6")}
                  </span>
                  <span className="text-wrapper-49">*</span>
                  <span className="text-wrapper-48">
                    {t("benefits.list.7")}
                  </span>
                </p>
              </div>
              <div className="frame-85">
                <div className="text-wrapper-46">+</div>
                <p className="text-wrapper-47">{t("benefits.list.8")}</p>
              </div>
            </div>
          </div>
        </div>
        <div id="Properties" className="frame-86">
          <div className="text-wrapper-50">{t("properties.title")}</div>
          <div className="frame-78">
            <div className="disponibilidad-2">{t("properties.subtitle")}</div>
            <div className="real-estate-card-14">
              <div className="slider-wrapper">
                <div className="slider-4">
                  <div className="ellipse-5" />
                  <div className="ellipse-6" />
                </div>
              </div>
              <div className="frame-87">
                <div className="frame-88">
                  <div className="frame-89">
                    <div className="TULUM-2">
                      {t("properties.card.title_bronze")}
                    </div>
                    <div className="group-wrapper">
                      <div className="overlap-group-wrapper">
                        <div className="overlap-group-2">
                          <div className="text-wrapper-51">11.000 €</div>
                          <img
                            className="line-7"
                            alt="Line"
                            src="/img/line-42.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-89">
                    <Status
                      className="design-component-instance-node-3"
                      language="esp"
                      size="mobile"
                      status="finansed"
                      text={t("properties.card.status")}
                    />
                    <div className="text-wrapper-52">10.000 €</div>
                  </div>
                </div>
                <div className="frame-80">
                  <div className="frame-90">
                    <div className="text-wrapper-53">
                      {t("properties.card.country")}
                    </div>
                    <div className="text-wrapper-44">
                      {t("properties.card.country_mexico")}
                    </div>
                  </div>
                  <div className="frame-90">
                    <div className="text-wrapper-53">
                      {t("properties.card.place")}
                    </div>
                    <div className="text-wrapper-44">
                      {t("properties.card.place_riviera_maya")}
                    </div>
                  </div>
                  <div className="frame-90">
                    <div className="text-wrapper-53">
                      {t("properties.card.rooms")}
                    </div>
                    <div className="text-wrapper-44">1</div>
                  </div>
                  <div className="frame-90">
                    <div className="text-wrapper-53">
                      {t("properties.card.sleeping_places")}
                    </div>
                    <div className="text-wrapper-44">4</div>
                  </div>
                  <div className="frame-90">
                    <div className="text-wrapper-53">
                      {t("properties.card.type_of_property")}
                    </div>
                    <div className="text-wrapper-44">
                      {t("properties.card.type_of_property_apartment")}
                    </div>
                  </div>
                </div>
                <p className="la-fotograf-a-puede-2">
                  {t("properties.card.note")}
                </p>
              </div>
            </div>
            <div className="real-estate-card-14">
              <div className="frame-91">
                <div className="slider-4">
                  <div className="ellipse-5" />
                  <div className="ellipse-6" />
                </div>
              </div>
              <div className="frame-87">
                <div className="frame-88">
                  <div className="frame-89">
                    <div className="TULUM-2">
                      {t("properties.card.title_silver")}
                    </div>
                    <div className="group-wrapper">
                      <div className="group-2">
                        <div className="overlap-group-3">
                          <div className="text-wrapper-51">14.000 €</div>
                          <img
                            className="line-7"
                            alt="Line"
                            src="/img/line-42.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-89">
                    <Status
                      className="design-component-instance-node-3"
                      language="esp"
                      size="mobile"
                      status="finansed"
                      text={t("properties.card.status")}
                    />
                    <div className="text-wrapper-52">13.000 €</div>
                  </div>
                </div>
                <div className="frame-80">
                  <div className="frame-90">
                    <div className="text-wrapper-53">
                      {t("properties.card.country")}
                    </div>
                    <div className="text-wrapper-44">
                      {t("properties.card.country_mexico")}
                    </div>
                  </div>
                  <div className="frame-90">
                    <div className="text-wrapper-53">
                      {t("properties.card.place")}
                    </div>
                    <div className="text-wrapper-44">
                      {t("properties.card.place_riviera_maya")}
                    </div>
                  </div>
                  <div className="frame-90">
                    <div className="text-wrapper-53">
                      {t("properties.card.rooms")}
                    </div>
                    <div className="text-wrapper-44">1</div>
                  </div>
                  <div className="frame-90">
                    <div className="text-wrapper-53">
                      {t("properties.card.sleeping_places")}
                    </div>
                    <div className="text-wrapper-44">4</div>
                  </div>
                  <div className="frame-90">
                    <div className="text-wrapper-53">
                      {t("properties.card.type_of_property")}
                    </div>
                    <div className="text-wrapper-44">
                      {t("properties.card.type_of_property_loft")}
                    </div>
                  </div>
                </div>
                <p className="la-fotograf-a-puede-2">
                  {t("properties.card.note")}
                </p>
              </div>
            </div>
            <div className="real-estate-card-14">
              <div className="frame-92">
                <div className="slider-4">
                  <div className="ellipse-5" />
                  <div className="ellipse-6" />
                </div>
              </div>
              <div className="frame-87">
                <div className="frame-88">
                  <div className="frame-89">
                    <div className="TULUM-2">
                      {t("properties.card.title_gold")}
                    </div>
                    <div className="group-wrapper">
                      <div className="group-3">
                        <div className="overlap-group-4">
                          <div className="text-wrapper-51">17.000 €</div>
                          <img
                            className="line-7"
                            alt="Line"
                            src="/img/line-42.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-89">
                    <Status
                      className="design-component-instance-node-3"
                      language="esp"
                      size="mobile"
                      status="finansed"
                      text={t("properties.card.status")}
                    />
                    <div className="text-wrapper-52">16.000 €</div>
                  </div>
                </div>
                <div className="frame-80">
                  <div className="frame-90">
                    <div className="text-wrapper-53">
                      {t("properties.card.country")}
                    </div>
                    <div className="text-wrapper-44">
                      {t("properties.card.country_mexico")}
                    </div>
                  </div>
                  <div className="frame-90">
                    <div className="text-wrapper-53">
                      {t("properties.card.place")}
                    </div>
                    <div className="text-wrapper-44">
                      {t("properties.card.place_riviera_maya")}
                    </div>
                  </div>
                  <div className="frame-90">
                    <div className="text-wrapper-53">
                      {t("properties.card.rooms")}
                    </div>
                    <div className="text-wrapper-44">2</div>
                  </div>
                  <div className="frame-90">
                    <div className="text-wrapper-53">
                      {t("properties.card.sleeping_places")}
                    </div>
                    <div className="text-wrapper-44">6</div>
                  </div>
                  <div className="frame-90">
                    <div className="text-wrapper-53">
                      {t("properties.card.type_of_property")}
                    </div>
                    <div className="text-wrapper-44">
                      {t("properties.card.type_of_property_house")}
                    </div>
                  </div>
                </div>
                <p className="la-fotograf-a-puede-2">
                  {t("properties.card.note")}
                </p>
              </div>
            </div>
          </div>
          <div className="frame-78">
            <div className="disponibilidad-2">{t("properties.subtitle2")}</div>
            <div className="real-estate-card-14">
              <div className="frame-93">
                <div className="slider-4">
                  <div className="ellipse-5" />
                  <div className="ellipse-6" />
                </div>
              </div>
              <div className="frame-87">
                <div className="frame-88">
                  <div className="frame-89">
                    <div className="TULUM-2">
                      {t("properties.card.title_vip1")}
                    </div>
                    <div className="group-wrapper">
                      <div className="group-4">
                        <div className="overlap-group-5">
                          <div className="text-wrapper-51">30.000 €</div>
                          <img
                            className="line-7"
                            alt="Line"
                            src="/img/line-42.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-89">
                    <Status
                      className="design-component-instance-node-3"
                      language="esp"
                      size="mobile"
                      status="finansed"
                      text={t("properties.card.status")}
                    />
                    <div className="text-wrapper-52">25.000 €</div>
                  </div>
                </div>
                <div className="frame-80">
                  <div className="frame-90">
                    <div className="text-wrapper-53">
                      {t("properties.card.country")}
                    </div>
                    <div className="text-wrapper-44">
                      {t("properties.card.country_mexico")}
                    </div>
                  </div>
                  <div className="frame-90">
                    <div className="text-wrapper-53">
                      {t("properties.card.place")}
                    </div>
                    <div className="text-wrapper-44">
                      {t("properties.card.place_riviera_maya")}
                    </div>
                  </div>
                  <div className="frame-90">
                    <div className="text-wrapper-53">
                      {t("properties.card.rooms")}
                    </div>
                    <div className="text-wrapper-44">2</div>
                  </div>
                  <div className="frame-90">
                    <div className="text-wrapper-53">
                      {t("properties.card.sleeping_places")}
                    </div>
                    <div className="text-wrapper-44">6</div>
                  </div>
                  <div className="frame-90">
                    <div className="text-wrapper-53">
                      {t("properties.card.type_of_property")}
                    </div>
                    <p className="text-wrapper-44">
                      {t("properties.card.type_of_property_house_land")}
                    </p>
                  </div>
                </div>
                <div className="frame-89">
                  <WarningShieldCheck1 className="interface" />
                  <InterfaceHeart />
                  <img
                    className="img-3"
                    alt="Gym"
                    src="/img/gym-3914460-1-7.svg"
                  />
                  <img
                    className="img-3"
                    alt="Hands holding"
                    src="/img/hands-holding-diamond-9844423-1-7.svg"
                  />
                  <img
                    className="img-3"
                    alt="Room service"
                    src="/img/room-service-5070430-1-7.svg"
                  />
                  <EnvironmentLeaf className="interface" color="#6957DA" />
                  <NavigationCarAuto2 className="interface" color="#6957DA" />
                  <UmbrellaWrapper />
                  <DiamondWrapper />
                </div>
                <p className="la-fotograf-a-puede-2">
                  {t("properties.card.note")}
                </p>
              </div>
              <Button
                buttonClassName="button-8 invisible"
                className="design-component-instance-node-4"
                color="violet"
                device="mobile"
                size="m"
                state="default"
                text="MÁS INFORMACIÓN"
              />
            </div>
            <div className="real-estate-card-14">
              <div className="frame-94">
                <div className="slider-4">
                  <div className="ellipse-5" />
                  <div className="ellipse-6" />
                </div>
              </div>
              <div className="frame-87">
                <div className="frame-88">
                  <div className="frame-89">
                    <div className="TULUM-2">
                      {t("properties.card.title_vip2")}
                    </div>
                    <div className="group-wrapper">
                      <div className="group-5">
                        <div className="overlap-group-6">
                          <div className="text-wrapper-51">40.000 €</div>
                          <img
                            className="line-7"
                            alt="Line"
                            src="/img/line-42.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-89">
                    <Status
                      className="design-component-instance-node-3"
                      language="esp"
                      size="mobile"
                      status="finansed"
                      text={t("properties.card.status")}
                    />
                    <div className="text-wrapper-52">35.000 €</div>
                  </div>
                </div>
                <div className="frame-80">
                  <div className="frame-90">
                    <div className="text-wrapper-53">
                      {t("properties.card.country")}
                    </div>
                    <div className="text-wrapper-44">
                      {t("properties.card.country_mexico")}
                    </div>
                  </div>
                  <div className="frame-90">
                    <div className="text-wrapper-53">
                      {t("properties.card.place")}
                    </div>
                    <div className="text-wrapper-44">
                      {t("properties.card.place_riviera_maya")}
                    </div>
                  </div>
                  <div className="frame-90">
                    <div className="text-wrapper-53">
                      {t("properties.card.rooms")}
                    </div>
                    <div className="text-wrapper-44">3</div>
                  </div>
                  <div className="frame-90">
                    <div className="text-wrapper-53">
                      {t("properties.card.sleeping_places")}
                    </div>
                    <div className="text-wrapper-44">
                      {t("properties.card.from")} 8 {t("properties.card.to")} 10
                    </div>
                  </div>
                  <div className="frame-90">
                    <div className="text-wrapper-53">
                      {t("properties.card.type_of_property")}
                    </div>
                    <p className="text-wrapper-44">
                      {t("properties.card.type_of_property_house_land")}
                    </p>
                  </div>
                </div>
                <div className="frame-89">
                  <WarningShieldCheck1 className="interface" />
                  <InterfaceHeart01 className="interface" />
                  <img
                    className="img-3"
                    alt="Gym"
                    src="/img/gym-3914460-1-8.svg"
                  />
                  <img
                    className="img-3"
                    alt="Hands holding"
                    src="/img/hands-holding-diamond-9844423-1-8.svg"
                  />
                  <img
                    className="img-3"
                    alt="Room service"
                    src="/img/room-service-5070430-1-8.svg"
                  />
                  <EnvironmentLeaf className="interface" color="#6957DA" />
                  <NavigationCarAuto2 className="interface" color="#6957DA" />
                  <Umbrella className="interface" />
                  <DiamondWrapper />
                </div>
                <p className="la-fotograf-a-puede-2">
                  {t("properties.card.note")}
                </p>
              </div>
              <Button
                buttonClassName="button-8"
                className="design-component-instance-node-4 invisible"
                color="violet"
                device="mobile"
                size="m"
                state="default"
                text="MÁS INFORMACIÓN"
              />
            </div>
            <div className="real-estate-card-14">
              <div className="frame-95">
                <div className="slider-4">
                  <div className="ellipse-5" />
                  <div className="ellipse-6" />
                </div>
              </div>
              <div className="frame-87">
                <div className="frame-88">
                  <div className="frame-89">
                    <div className="TULUM-2">
                      {t("properties.card.title_vip3")}
                    </div>
                    <div className="group-wrapper">
                      <div className="group-4">
                        <div className="overlap-group-5">
                          <div className="text-wrapper-51">50.000 €</div>
                          <img
                            className="line-7"
                            alt="Line"
                            src="/img/line-42.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-89">
                    <Status
                      className="design-component-instance-node-3"
                      language="esp"
                      size="mobile"
                      status="finansed"
                      text={t("properties.card.status")}
                    />
                    <div className="text-wrapper-52">45.000 €</div>
                  </div>
                </div>
                <div className="frame-80">
                  <div className="frame-90">
                    <div className="text-wrapper-53">
                      {t("properties.card.country")}
                    </div>
                    <div className="text-wrapper-44">
                      {t("properties.card.country_mexico")}
                    </div>
                  </div>
                  <div className="frame-90">
                    <div className="text-wrapper-53">
                      {t("properties.card.place")}
                    </div>
                    <div className="text-wrapper-44">
                      {t("properties.card.place_riviera_maya")}
                    </div>
                  </div>
                  <div className="frame-90">
                    <div className="text-wrapper-53">
                      {t("properties.card.rooms")}
                    </div>
                    <div className="text-wrapper-44">4</div>
                  </div>
                  <div className="frame-90">
                    <div className="text-wrapper-53">
                      {t("properties.card.sleeping_places")}
                    </div>
                    <div className="text-wrapper-44">10-12</div>
                  </div>
                  <div className="frame-90">
                    <div className="text-wrapper-53">
                      {t("properties.card.type_of_property")}
                    </div>
                    <p className="text-wrapper-44">
                      {t("properties.card.type_of_property_house_land")}
                    </p>
                  </div>
                </div>
                <div className="frame-89">
                  <WarningShieldCheck1 className="interface" />
                  <InterfaceHeart />
                  <img
                    className="img-3"
                    alt="Gym"
                    src="/img/gym-3914460-1-9.svg"
                  />
                  <img
                    className="img-3"
                    alt="Hands holding"
                    src="/img/hands-holding-diamond-9844423-1-9.svg"
                  />
                  <img
                    className="img-3"
                    alt="Room service"
                    src="/img/room-service-5070430-1-9.svg"
                  />
                  <EnvironmentLeaf className="interface" color="#6957DA" />
                  <NavigationCarAuto2 className="interface" color="#6957DA" />
                  <UmbrellaWrapper />
                  <DiamondWrapper />
                </div>
                <p className="la-fotograf-a-puede-2">
                  {t("properties.card.note")}
                </p>
              </div>
              <Button
                buttonClassName="button-8"
                className="design-component-instance-node-4 invisible"
                color="violet"
                device="mobile"
                size="m"
                state="default"
                text="MÁS INFORMACIÓN"
              />
            </div>
          </div>
          <div className="frame-79">
            <Form
              className="mobileForm"
              formId="landing-page-caribs-mobile-form2"
              backendCall={handleBackendCall}
            >
              {t("form.button")}
            </Form>
          </div>
        </div>
        <div className="frame-83">
          <p id="Riviera" className="text-wrapper-39">
            {t("why_invest.title")}
          </p>
          <div className="frame-96">
            <div className="card-middle-mobile">
              <div className="frame-97">
                <div className="frame-98">
                  <div className="visitaci-n">
                    {t("why_invest.destination")}
                  </div>
                  <Trophy
                    className="design-component-instance-node-5"
                    trophy="/img/trophy-3.svg"
                  />
                </div>
                <p className="text-wrapper-54">
                  {t("why_invest.destination_text")}
                </p>
              </div>
            </div>
            <div className="card-middle-mobile-2">
              <div className="frame-97">
                <div className="frame-99">
                  <div className="visitaci-n-2">{t("why_invest.trends")}</div>
                  <NavigationBuilding
                    className="design-component-instance-node-5"
                    navigationBuilding="/img/navigation-building-02-2.svg"
                  />
                </div>
                <p className="text-wrapper-55">{t("why_invest.trends_text")}</p>
              </div>
            </div>
            <div className="card-middle-mobile-3">
              <div className="frame-97">
                <div className="frame-99">
                  <div className="visitaci-n-2">{t("why_invest.security")}</div>
                  <WarningShieldCheck
                    className="design-component-instance-node-5"
                    warningShieldCheck="/img/warning-shield-check-12.svg"
                  />
                </div>
                <p className="text-wrapper-55">
                  {t("why_invest.security_text")}
                </p>
              </div>
            </div>
            <div className="card-middle-mobile-4">
              <div className="frame-97">
                <div className="frame-99">
                  <div className="visitaci-n">
                    {t("why_invest.capital_gain")}
                  </div>
                  <InterfaceChartLine
                    className="design-component-instance-node-5"
                    interfaceChartLine="/img/interface-chart-line-3.svg"
                  />
                </div>
                <p className="text-wrapper-54">
                  {t("why_invest.capital_gain_text")}
                </p>
              </div>
            </div>
            <div className="card-middle-mobile-5">
              <div className="frame-97">
                <div className="frame-99">
                  <div className="visitaci-n">{t("why_invest.location")}</div>
                  <Globe
                    className="design-component-instance-node-5"
                    globe="/img/globe-02-2.svg"
                  />
                </div>
                <p className="text-wrapper-54">
                  {t("why_invest.location_text")}
                </p>
              </div>
            </div>
            <div className="card-middle-mobile-3">
              <div className="frame-97">
                <div className="frame-99">
                  <div className="visitaci-n-2">{t("why_invest.climate")}</div>
                  <EnvironmentSun
                    className="design-component-instance-node-5"
                    environmentSun="/img/environment-sun-2.svg"
                  />
                </div>
                <p className="text-wrapper-55">
                  {t("why_invest.climate_text")}
                </p>
              </div>
            </div>
            <div className="card-middle-mobile-6">
              <div className="frame-97">
                <div className="frame-100">
                  <div className="visitaci-n-3">
                    {t("why_invest.cosmopolitan")}
                  </div>
                  <SmileyHappy
                    className="design-component-instance-node-5"
                    smileyHappy="/img/smiley-happy-3.svg"
                  />
                </div>
                <p className="text-wrapper-55">
                  {t("why_invest.cosmopolitan_text")}
                </p>
              </div>
            </div>
            <div className="card-middle-mobile-7">
              <div className="frame-97">
                <div className="frame-99">
                  <div className="visitaci-n-2">{t("why_invest.leisure")}</div>
                  <img
                    className="interface-star"
                    alt="Interface star"
                    src="/img/interface-star-1.svg"
                  />
                </div>
                <p className="text-wrapper-55">
                  {t("why_invest.leisure_text")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="frame-78">
          <p className="text-wrapper-39">{t("join_revolution.title")}</p>
          <p className="text-wrapper-56">{t("join_revolution.subtitle")}</p>
          <div className="frame-79">
            <Form
              className="mobileForm"
              formId="landing-page-caribs-mobile-form3"
              backendCall={handleBackendCall}
            >
              {t("form.button")}
            </Form>
          </div>
        </div>
        <div id="video" className="frame-78">
          <div className="text-wrapper-39">{t("promo_video.title")}</div>
          <p className="text-wrapper-56">{t("promo_video.subtitle")}</p>
          <div className="frame-101">
            <iframe
              src={videoLinks.ButtomLanding}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </div>
        <div className="frame-83">
          <div id="Strengths" className="text-wrapper-39">
            {t("strengths.title")}
          </div>
          <div className="frame-96">
            <img
              className="rectangle-5"
              alt="Rectangle"
              src="/img/rectangle-367-1.png"
            />
            <div className="card-middle-mobile-8">
              <div className="frame-97">
                <div className="element-reg-strate">
                  {t("strengths.experience")}
                </div>
                <p className="text-wrapper-54">
                  {t("strengths.experience_text")}
                </p>
              </div>
            </div>
            <div className="card-middle-mobile-9">
              <div className="frame-97">
                <div className="element-reg-strate-2">
                  {t("strengths.diversity")}
                </div>
                <p className="s-lo-necesitas-un">
                  {t("strengths.diversity_text")}
                </p>
              </div>
            </div>
            <div className="card-middle-mobile-10">
              <div className="frame-97">
                <div className="element-reg-strate-2">
                  {t("strengths.creativity")}
                </div>
                <p className="s-lo-necesitas-un">
                  {t("strengths.creativity_text")}
                </p>
              </div>
            </div>
            <div className="card-middle-mobile-8">
              <div className="frame-97">
                <div className="element-reg-strate">
                  {t("strengths.scalability")}
                </div>
                <p className="text-wrapper-54">
                  {t("strengths.scalability_text")}
                </p>
              </div>
            </div>
            <div className="card-middle-mobile-8">
              <div className="frame-97">
                <div className="element-reg-strate">
                  {t("strengths.innovation")}
                </div>
                <p className="text-wrapper-54">
                  {t("strengths.innovation_text")}
                </p>
              </div>
            </div>
            <div className="card-middle-mobile-11">
              <div className="frame-97">
                <div className="element-reg-strate-2">
                  {t("strengths.loyalty")}
                </div>
                <p className="s-lo-necesitas-un">
                  {t("strengths.loyalty_text")}
                </p>
              </div>
            </div>
            <div className="card-middle-mobile-12">
              <div className="frame-97">
                <div className="element-reg-strate-2">
                  {t("strengths.alliances")}
                </div>
                <p className="s-lo-necesitas-un">
                  {t("strengths.alliances_text")}
                </p>
              </div>
            </div>
            <div className="card-middle-mobile-8">
              <div className="frame-97">
                <div className="element-reg-strate">
                  {t("strengths.liquidity")}
                </div>
                <p className="text-wrapper-54">
                  {t("strengths.liquidity_text")}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="frame-wrapper-2">
          <div
            id="team"
            className="frame-69"
            onClick={() => setTeamBlockIsOpen((prev) => !prev)}
          >
            <div className="text-wrapper-20">{t("team.title")}</div>
            <ArrowChevronRight
              className={teamBlockIsOpen ? "arrow-rotate" : ""}
            />
          </div>
          {teamBlockIsOpen && (
            <ul className="team-block-list">
              {teamList.map((team) => (
                <TeamBlock
                  key={crypto.randomUUID()}
                  team={t(`team.${team}`)}
                  id={team}
                />
              ))}
            </ul>
          )}
        </div>

        <div className="frame-76">
          <div
            className="frame-102"
            onClick={() => setFaqOpen((prev) => !prev)}
          >
            <div className="text-wrapper-57">{t("faq.title")}</div>
            <ArrowChevronRight className={faqOpen ? "arrow-rotate" : ""} />
          </div>
          {faqOpen && <FaqBlock />}
        </div>

        <div className="frame-112">
          <p className="text-wrapper-50">{t("pre_sale.title")}</p>
          <div className="frame-113">
            <div className="frame-97">
              <p className="text-wrapper-61">{t("pre_sale.subtitle")}</p>
              <Timer
                className="timer-5 invisible"
                divClassName="timer-8"
                rectangleClassName="timer-8"
                rectangleClassNameOverride="timer-8"
                size="mobile"
                spanClassName="timer-6"
                spanClassName1="timer-6"
                spanClassName2="timer-7"
                spanClassName3="timer-6"
                spanClassName4="timer-7"
                spanClassName5="timer-6"
                spanClassName6="timer-7"
                spanClassNameOverride="timer-7"
                text="m"
                text1="0 "
                text2="d"
                text3="0 "
                text4="h"
                text5="0 "
                text6="m"
                theme="light"
              />
            </div>
            <div id="presale" className="frame-97">
              <div className="frame-97">
                <p className="text-wrapper-62">
                  {t("pre_sale.content_subtitle")}
                </p>
              </div>
              <div className="frame-114">
                <Form
                  ref={focusForm}
                  className="mobileForm"
                  formId="landing-page-caribs-mobile-form4"
                  backendCall={handleBackendCall}
                >
                  {t("form.button")}
                </Form>
              </div>
              <p className="text-wrapper-63">{t("pre_sale.caption")}</p>
            </div>
          </div>
        </div>
        <div className="footer-landing">
          <div className="frame-115">
            <p className="text-wrapper-64">{t("footer.form_title")}</p>
            <div className="frame-116">
              <div className="frame-117">
                <Form
                  className="mobileForm mobileSecond"
                  formId="landing-page-caribs-mobile-form5"
                  backendCall={handleBackendCall}
                >
                  {t("form.footer_button")}
                </Form>
              </div>
            </div>
            <div className="frame-119">
              <div className="frame-120">
                <div className="text-wrapper-65">{t("footer.menu.title")}</div>
                <div className="frame-80">
                  <div className="text-wrapper-66">
                    <Link to="Project"> {t("header.project")}</Link>
                  </div>
                  <div className="text-wrapper-55">
                    <Link to="Benefits"> {t("header.benefits")}</Link>
                  </div>
                  <div className="text-wrapper-55">
                    <Link to="Properties"> {t("header.properties")}</Link>
                  </div>
                  <div className="text-wrapper-55">
                    <Link to="Riviera">{t("header.riviera_maya")}</Link>
                  </div>
                  <div className="text-wrapper-55">
                    <Link to="video">
                      <div className="div-3">{t("header.videos")}</div>
                    </Link>
                  </div>
                  <div className="text-wrapper-55">
                    <Link to="Strengths">{t("header.strengths")}</Link>
                  </div>
                  <div className="text-wrapper-55 invisible">
                    <Link to="team">{t("header.team")}</Link>
                  </div>
                  <div className="text-wrapper-55 invisible">FAQ</div>
                  <div className="text-wrapper-55">
                    <Link to="presale">{t("header.pre_sale")}</Link>
                  </div>
                </div>
              </div>
              <div className="frame-121">
                <div className="text-wrapper-65">
                  {t("footer.contact.title")}
                </div>
                <div className="frame-80">
                  <div className="frame-122">
                    <div className="text-wrapper-67">
                      {t("footer.contact.office")}
                    </div>
                    <p className="text-wrapper-68">
                      {t("footer.contact.address")}
                    </p>
                  </div>
                  <div className="frame-122">
                    <div className="text-wrapper-67">
                      {t("footer.contact.social_networks")}
                    </div>
                    <div className="text-wrapper-55">
                      <a target="_blank" href={socialLinks.Telegram}>
                        {t("footer.contact.telegram")}
                      </a>
                    </div>
                    <div className="text-wrapper-55">
                      <a target="_blank" href={socialLinks.Instagram}>
                        {t("footer.contact.instagram")}
                      </a>
                    </div>
                    <div className="text-wrapper-55">
                      <a target="_blank" href={socialLinks.Twitter}>
                        X(Twitter)
                      </a>
                    </div>
                    <div className="text-wrapper-55">
                      <a target="_blank" href={socialLinks.Youtube}>
                        Youtube
                      </a>
                    </div>
                    <div className="text-wrapper-55">
                      <a target="_blank" href={socialLinks.Facebook}>
                        Facebook
                      </a>
                    </div>
                  </div>
                  <div className="frame-122">
                    <div className="text-wrapper-67">
                      {t("footer.contact.email")}
                    </div>
                    <div className="text-wrapper-68">sales@rentoncha.in</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-123">
              <div className="frame-124">
                <div className="links-3 invisible">
                  <div className="text-wrapper-53">
                    {t("footer.privacy_policy")}
                  </div>
                  <img
                    className="line-10"
                    alt="Line"
                    src="/img/line-12-119.svg"
                  />
                </div>
                <div className="text-wrapper-69 invisible">
                  {t("footer.terms")}
                </div>
              </div>
              <div className="frame-124">
                <div className="links-3 invisible">
                  <div className="text-wrapper-53">
                    {t("footer.cookie_policy")}
                  </div>
                  <img
                    className="line-10"
                    alt="Line"
                    src="/img/line-12-120.svg"
                  />
                </div>
                <div className="text-wrapper-69">©2023 Rent Onchain</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
