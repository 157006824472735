/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const EnvironmentSun1 = ({ className }) => {
  return (
    <svg
      className={`environment-sun-1 ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M16.0013 5.33335V2.66669M16.0013 26.6667V29.3334M8.55359 8.55231L6.66797 6.66669M23.6387 23.6374L25.5243 25.523M5.33464 16H2.66797M26.668 16H29.3346M23.6393 8.55231L25.5249 6.66669M8.55424 23.6374L6.66862 25.523M16.0013 22.6667C12.3194 22.6667 9.33464 19.6819 9.33464 16C9.33464 12.3181 12.3194 9.33335 16.0013 9.33335C19.6832 9.33335 22.668 12.3181 22.668 16C22.668 19.6819 19.6832 22.6667 16.0013 22.6667Z"
        stroke="#1A1A1C"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </svg>
  );
};
