/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const Diamond = ({ color = "#6957DA", className }) => {
  return (
    <svg
      className={`diamond ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M10.5072 3.83009L8.45669 9.14272L11.9984 20.1699M11.9984 20.1699L15.6334 9.04951L13.6761 3.83009M11.9984 20.1699L2.39844 9.23592M11.9984 20.1699L21.5984 9.23592M2.39844 9.23592L6.49941 3.83009H17.5907L21.5984 9.23592M2.39844 9.23592H21.5984"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

Diamond.propTypes = {
  color: PropTypes.string,
};
