/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Globe021 = ({ className }) => {
  return (
    <svg
      className={`globe-02-1 ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M25 23.9375L20.6154 18.7692H17.8462L16 16.9231L19.6923 11.3846H26.1538M9.53846 6.76923L11.832 9.06275C12.6806 9.91134 12.9418 11.184 12.4961 12.2982L12.1389 13.1911C11.6834 14.3301 10.5802 15.0769 9.35352 15.0769H4.92308M28 16C28 22.6274 22.6274 28 16 28C9.37258 28 4 22.6274 4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </svg>
  );
};
