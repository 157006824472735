/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Umbrella2 = ({ className }) => {
  return (
    <svg
      className={`umbrella-2 ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M12.8727 12.6751L10.4496 16.8721M17.2671 3.91274C15.2148 5.16498 11.0638 8.44036 10.8788 11.524M18.264 4.48829C18.2512 6.91805 17.5674 12.1952 14.9349 13.8658M1.8 19.1897L4.33345 18.0752C5.8815 17.3941 7.58717 17.1525 9.26347 17.3767L9.34105 17.3871C11.0431 17.6148 12.6477 18.3139 13.9734 19.4053L16 21.0736M6.66621 9.09185L19.0791 16.2584C19.7812 16.6638 20.6789 16.4232 21.0842 15.7212C23.3811 11.7428 22.0181 6.65568 18.0397 4.35877L17.4913 4.04218C13.513 1.74527 8.42585 3.10835 6.12894 7.08672C5.7236 7.78878 5.96415 8.68651 6.66621 9.09185Z"
        stroke="#6957DA"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};
