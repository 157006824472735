/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Status = ({ status, size, language, className, text = "Finansiado" }) => {
  return (
    <div className={`status ${status} size-13-${size} ${className}`}>
      <div className="finansiado">
        {status === "finansed" && language === "esp" && <>{text}</>}

        {status === "finansed" && language === "eng" && <>Financed</>}

        {status === "in-operation" && language === "esp" && <>En explotación</>}

        {status === "in-operation" && language === "eng" && <>In operation</>}

        {status === "under-renovation" && language === "esp" && <>En reforma</>}

        {language === "eng" && status === "under-renovation" && <>Under renovation</>}

        {status === "reformed" && language === "esp" && <>Reformado</>}

        {status === "reformed" && language === "eng" && <>Reformed</>}

        {language === "esp" && status === "coming-soon" && <>Próximamente</>}

        {language === "eng" && status === "coming-soon" && <>Coming soon</>}

        {status === "rented" && language === "esp" && <>Alquilado</>}

        {language === "eng" && status === "rented" && <>Rented</>}
      </div>
    </div>
  );
};

Status.propTypes = {
  status: PropTypes.oneOf(["coming-soon", "finansed", "rented", "under-renovation", "in-operation", "reformed"]),
  size: PropTypes.oneOf(["desktop", "mobile"]),
  language: PropTypes.oneOf(["esp", "eng"]),
  text: PropTypes.string,
};
