/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const ArrowCaretRightSm5 = ({ className }) => {
  return null;
  // <svg
  //   className={`arrow-caret-right-sm-5 ${className}`}
  //   fill="none"
  //   height="40"
  //   viewBox="0 0 40 40"
  //   width="40"
  //   xmlns="http://www.w3.org/2000/svg"
  // >
  //   <path
  //     className="path"
  //     d="M18.3334 15L23.3334 20L18.3334 25"
  //     stroke="white"
  //     strokeLinecap="round"
  //     strokeLinejoin="round"
  //     strokeWidth="2"
  //   />
  // </svg>
};
