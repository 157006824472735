import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import "./style.scss";

export const Form = forwardRef(
  (
    { className = "firstVer", children, formId, bigInputs, backendCall },
    ref
  ) => {
    const { t } = useTranslation("global");
    const urlEndpoint =
      "https://formreceiverapi-e2towykpua-uc.a.run.app/receiver/forms/";

    const handleSubmit = async (event) => {
      event.preventDefault();
      const form = event.target;
      const fullName = form.fullName.value;
      const email = form.email.value;
      const phone = form.phone.value;
      let error = undefined;
      console.debug(
        `Form to send. Full name: ${fullName}, email: ${email}, phone: ${phone}, formId: ${formId}`
      );
      let isOk = true;
      if (formId === "" || formId === undefined) {
        console.warn("FormId is empty");
        isOk = false;
      } else if (fullName === "" || fullName === undefined) {
        console.warn("Fullname is empty");
        isOk = false;
      } else if (email === "" || fullName === email) {
        console.warn("Email is empty");
        isOk = false;
      }

      if (isOk) {
        // Send this form
        try {
          const jsonData = { fullName, email, phone };
          const res = await axios.post(`${urlEndpoint}${formId}`, jsonData);
          console.debug("Response to request:", res.data);
          // Reset form after good response
          if (res.data.isOk) {
            console.debug("Form reset", res.data.isOk);
            form.reset();
            isOk = true;
          } else {
            isOk = false;
          }
        } catch (err) {
          console.error("Error when send request:", err);
          isOk = false;
          error = err;
        }
        backendCall(isOk, error);
      }
    };

    return (
      <form onSubmit={handleSubmit} className={`default ${className}`}>
        <div className="inputsWrapper">
          <input
            ref={ref}
            name="fullName"
            className={bigInputs ? "inputStyleBig" : "inputStyle"}
            type="text"
            placeholder={t("form.name")}
          />
          <input
            className={bigInputs ? "inputStyleBig" : "inputStyle"}
            name="email"
            type="email"
            placeholder={t("form.email")}
          />
          <input
            className={bigInputs ? "inputStyleBig" : "inputStyle"}
            name="phone"
            type="text"
            placeholder={t("form.phone")}
          />
        </div>

        <div className="checkboxWrapper invisible">
          <label>
            <input type="checkbox" style={{ width: "32px", height: "32px" }} />
            <span>{t("form.checkbox")}</span>
          </label>
          <div className="textCheckbox">{t("form.checkbox2")}</div>
        </div>

        <button type="submit" className="btnForm">
          {children}
        </button>
      </form>
    );
  }
);

Form.propTypes = {
  state: PropTypes.oneOf(["defolt", "error", "active", "default"]),
  size: PropTypes.oneOf(["XL", "XS"]),
  formType: PropTypes.string,
  text: PropTypes.string,
  text1: PropTypes.string,
  line: PropTypes.string,
  visible: PropTypes.bool,
  img: PropTypes.string,
};
