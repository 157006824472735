{
  "main_heading": "REAL ESTATE INVESTMENT IN PARADISE, YOUR BEST CHANCE",
  "header": {
    "project": "Project",
    "benefits": "Benefits",
    "properties": "Properties",
    "riviera_maya": "RIVIERA MAYA",
    "videos": "Videos",
    "strengths": "Strengths",
    "team": "Team",
    "faq": "FAQ",
    "pre_sale": "Pre-Sale"
  },
  "header_card": {
    "title1": "ENJOY LIFE IN YOUR HOME",
    "title2": "IN THE CARIBBEAN, WITHOUT WORRIES",
    "button": "DISCOVER IT NOW",
    "mobile_button": "JOIN NOW"
  },
  "slider": {
    "title": "FIRST PROJECT (CARIBBEAN)",
    "text1": "Welcome to a real estate investment in the Riviera Maya (Playa del Carmen, Tulum, Cancun).",
    "text2": "Our homes offer you the freedom to enjoy an apartment or house with no worries or maintenance costs."
  },
  "welcome": {
    "title": "WHY IS OUR REAL ESTATE INVESTMENT MODEL UNIQUE?",
    "subtitle": "Welcome to Rent Onchain, at the forefront of real estate investments!",
    "text1": "At Rent Onchain, your financial security is our top priority. Every transaction on our platform is backed by state-of-the-art Blockchain",
    "text2": "technology and smart contracts that are immutable, self-executing and 100% secure."
  },
  "form": {
    "name": "Name*",
    "email": "E-mail*",
    "phone": "Telephone",
    "checkbox": "I am not a robot.",
    "checkbox2": "Click to continue.",
    "button": "JOIN NOW",
    "footer_button": "SEND"
  },
  "benefits": {
    "title": "YOU WILL OBTAIN THE FOLLOWING BENEFITS",
    "list": {
      "1": "Invest from 10.000€ and acquire the right to live and enjoy, for life, one week a year in a wonderful home in the Caribbean.",
      "2": "Investment with high annual capital gain",
      "3": ".",
      "4": "Total freedom to choose when to go to the Caribbean.",
      "5": "You can invest in dollars, euros and/or crypto, you decide!",
      "6": "Your investment will be registered in the Blockchain",
      "7": ", so it will be public, encrypted, decentralized and secure.",
      "8": "From the 3rd year, you will be able to enjoy other destinations besides the Caribbean (we will have properties in Europe, the Caribbean, Indonesia, USA, etc.)."
    }
  },
  "properties": {
    "title": "PROPERTIES",
    "subtitle": "AVAILABILITY 01/01/24",
    "subtitle2": "AVAILABILITY 12/31/24",
    "card": {
      "title_bronze": "BRONZE",
      "title_silver": "SILVER",
      "title_gold": "GOLD",
      "title_vip1": "VIP 1",
      "title_vip2": "VIP 2",
      "title_vip3": "VIP 3",
      "status": "Pre-Sale",
      "country": "Country",
      "country_mexico": "Mexico",
      "place": "Place",
      "place_riviera_maya": "Caribbean (Riviera Maya)",
      "rooms": "Rooms",
      "sleeping_places": "Sleeping places",
      "type_of_property": "Type of property",
      "type_of_property_apartment": "Apartment",
      "type_of_property_loft": "Loft",
      "type_of_property_house": "House",
      "type_of_property_house_land": "House + land(> 600 m2)",
      "from": "from",
      "to": "to",
      "note": "*The photograph may not coincide with the assigned apartment, but it will have the same characteristics and will be published before the final purchase.",
      "button": "MORE INFORMATION"
    }
  },
  "why_invest": {
    "title": "WHY INVEST NOW IN THE RIVIERA MAYA?",
    "destination": "DESTINATION",
    "destination_text": "Mexico is the 6th most visited country in the world.",
    "trends": "TRENDS",
    "trends_text": "In the year 2022, the online search for real estate in the Riviera Maya increased by 68%.",
    "security": "SECURITY",
    "security_text": "The state of Quintana Roo is one of the safest in Mexico.",
    "capital_gain": "CAPITAL GAIN",
    "capital_gain_text": "Real estate capital gains have increased by 25% annually in recent years.",
    "location": "LOCATION",
    "location_text": "Excellent location and daily connections to major cities around the world.",
    "climate": "CLIMATE",
    "climate_text": "Tropical climate all year round with an average temperature of 27ºC.",
    "cosmopolitan": "COSMOPOLITAN",
    "cosmopolitan_text": "With more than 15.000 foreigners living in Quintana Roo.",
    "leisure": "LEISURE",
    "leisure_text": "Vibrant nightlife, gourmet restaurants, sightseeing and much more."
  },
  "join_revolution": {
    "title": "JOIN THE REAL ESTATE INVESTMENT REVOLUTION",
    "subtitle": " Want to learn more? Be among the first to be part of this revolution, join the pre-sale and discover how to invest wisely."
  },
  "promo_video": {
    "title": "WATCH OUR PROMOTIONAL VIDEOS",
    "subtitle": "To learn more about our proposal, watch our promotional videos."
  },
  "strengths": {
    "title": "STRENGTHS OF OUR COMPANY",
    "experience": "EXPERIENCE",
    "experience_text": "We are experts in different business areas that have joined together in an integral team for a guaranteed success.",
    "diversity": "DIVERSITY",
    "diversity_text": "Multidisciplinary team from 11 different countries with a wide range of skills and experience.",
    "creativity": "CREATIVITY",
    "creativity_text": "At the forefront of new trends, adapting to new technologies.",
    "scalability": "SCALABILITY",
    "scalability_text": "Our business model is highly scalable, allowing us, together with our investors, to easily expand to other countries.",
    "innovation": "INNOVATION",
    "innovation_text": "We think and analyze new formulas to solve traditional problems in a more effective way for a globalized world.",
    "loyalty": "LOYALTY",
    "loyalty_text": "Our goal is a long-term relationship with our clients/investors by offering them transparency and an excellent service.",
    "alliances": "ALLIANCES",
    "alliances_text": "We create links and alliances with other companies in the tourism and hospitality industry to offer the highest range and diversity of services to our clients.",
    "liquidity": "LIQUIDITY",
    "liquidity_text": "Thanks to our marketplace, our e-timesharing NFT can be assigned, transferred, sold or rented, making it a very flexible and attractive investment."
  },
  "team": {
    "title": "OUR TEAM",
    "developers": "DEVELOPERS",
    "design": "DESIGN",
    "operation": "OPERATION",
    "marketing": "MARKETING",
    "compliance_development": "COMPLIANCE DEVELOPMENT"
  },
  "faq": {
    "title": "FREQUENTLY ASKED QUESTIONS",
    "q1": "How to make the investment?",
    "a1": "Invest from 10,000 € (without any additional lifetime expenses) and you will obtain the right of use and enjoyment of one week a year for life in any of our available properties",
    "q2": "What guarantees of profitability do you offer?",
    "a2": "Our project is based on a solid strategy of growth and profitability taking into account the capital gain of the area, the natural increase in the value of the properties and the freedom to assign, transfer, donate, sell and rent this acquired right for life.",
    "q3": "What happens if I want to sell my investment?",
    "a3": "We would be the first ones interested in acquiring your right, but you can also sell it to whoever you want or use our marketplace or other similar ones where you are sure to find someone interested",
    "q4": "If one year I can't or don't want to go, what can I do?",
    "a4": "You can give it away so that someone you love can enjoy it or, if you want to make a profit and not worry about anything, we can rent it for you in our marketplace",
    "q5": "What is the tax treatment of my right of use and enjoyment?",
    "a5": "It depends on each country, we can advise you personally",
    "q6": "Do I need to travel to Mexico for any paperwork?",
    "a6": "NO. Everything will be done online",
    "q7": "When can I start enjoying my dream?",
    "a7": "From the second month after making the deposit",
    "q8": "Can I bring my pet to my home? ",
    "a8": "Of course, although please note that each home may have some restrictions in this regard, please check the conditions of use and enjoyment",
    "q9": "What security measures does the complex have?",
    "a9": "There is permanent private security at the entrance and security patrols around the perimeter",
    "q10": "What guarantee do I have that my investment is in good hands?",
    "a10": "All transactions will be recorded in the blockchain in a decentralized way so it will be public domain and immutable and with your personal information encrypted",
    "q11": "Can I inherit my acquired right?",
    "a11": "Yes, your right to the property is linked to a unique key, which as you know, is protected in the blockchain, when the time comes, we will advise you"
  },
  "pre_sale": {
    "title": "YOUR DREAMS START HERE!",
    "subtitle": "Sale of Vacation rental NFT's begins on july 1, 2024 COUNTDOWN:",
    "content_subtitle": "During the PRE-SALE we want to reward your confidence in us and that is why we offer you to win a free week:",
    "content_text": "If you purchase the NFT's before July 1, 2024, in addition to the savings for the pre-launch price, for every 10 NFT's you purchase (yours plus those you recommend), we will give you another one-week NFT, with the same rights and conditions.",
    "caption": "With Rent Onchain you will make an eco-sos-smart investment because it allows you to take full advantage of your home."
  },
  "footer": {
    "contact": {
      "title": "CONTACT US",
      "office": "Office",
      "address": "Square Can Bassa, 1 08402 Granollers (Barcelona) Spain",
      "social_networks": "Social medias",
      "telegram": "Telegram",
      "instagram": "Instagram",
      "email": "E-mail",
      "email_text": "sales@rentoncha.in"
    },
    "menu": {
      "title": "MENU",
      "project": "Project",
      "benefits": "Benefits",
      "properties": "Properties",
      "riviera_maya": "Riviera Maya",
      "videos": "Videos",
      "strengths": "Strengths",
      "team": "Team",
      "faq": "FAQ",
      "pre_sale": "Pre-Sale"
    },
    "form_title": "IF YOU ARE INTERESTED IN RECEIVING MORE INFORMATION ABOUT RENT ONCHAIN, SIGN UP FOR OUR NEWSLETTER AND BE THE FIRST TO KNOW ABOUT OUR LATEST UPDATES AND SPECIAL OFFERS",
    "terms": "Terms and conditions",
    "privacy_policy": "Privacy policy",
    "cookie_policy": "Cookie policy"
  },
  "msg": {
    "success_send": "YOUR REQUEST HAS BEEN SENT SUCCESSFULLY",
    "accept": "ACCEPT"
  }
}
