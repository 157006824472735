/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const WarningShieldCheck6 = ({ className }) => {
  return (
    <svg
      className={`warning-shield-check-6 ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M19.9987 12L14.6654 17.3333L11.9987 14.6667M26.6654 13.5534C26.6654 22.3111 20.0413 26.2374 17.2331 27.5085L17.2295 27.5101C16.9342 27.6437 16.7861 27.7108 16.4505 27.7684C16.238 27.8049 15.7608 27.8049 15.5482 27.7684C15.2113 27.7105 15.0617 27.6433 14.764 27.5085C11.9558 26.2374 5.33203 22.3111 5.33203 13.5534V8.26693C5.33203 6.77345 5.33203 6.02616 5.62268 5.45573C5.87834 4.95396 6.28599 4.54631 6.78776 4.29065C7.35819 4 8.10548 4 9.59896 4H22.399C23.8924 4 24.6381 4 25.2086 4.29065C25.7103 4.54631 26.1193 4.95396 26.375 5.45573C26.6654 6.0256 26.6654 6.77199 26.6654 8.26255V13.5534Z"
        stroke="#1A1A1C"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </svg>
  );
};
