import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const EnvironmentSun = ({ className, environmentSun = "/img/environment-sun.png" }) => {
  return <img className={`environment-sun ${className}`} alt="Environment sun" src={environmentSun} />;
};

EnvironmentSun.propTypes = {
  environmentSun: PropTypes.string,
};
