/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const NavigationBuilding = ({ className, navigationBuilding = "/img/navigation-building-02.png" }) => {
  return <img className={`navigation-building ${className}`} alt="Navigation building" src={navigationBuilding} />;
};

NavigationBuilding.propTypes = {
  navigationBuilding: PropTypes.string,
};
