import React, { useState, useEffect } from "react";
import { LandingPageXl } from "./screens/LandingPageXl";
import { LandingPageMobile } from "./screens/LandingPageMobile";

export const App = () => {
  console.log("window innerWidth", window.innerWidth);
  const [isMovil, setIsMovil] = useState(window.innerWidth < 1024);

  useEffect(() => {
    function handleResize() {
      setIsMovil(window.innerWidth < 1024);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return <>{isMovil ? <LandingPageMobile /> : <LandingPageXl />}</>;
};
