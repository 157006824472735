import React, { useState } from "react";
import { Button } from "../../components/Button";
import { CardMiniDesktop } from "../../components/CardMiniDesktop";
import { FooterLandingPage } from "../../components/FooterLandingPage";
import { HeaderTimesharing } from "../../components/HeaderTimesharing";
import { Timer } from "../../components/Timer";
import { RealEstateCard } from "../../components/RealEstateCard";
import { ArrowChevronRight } from "../../icons/ArrowChevronRight";
import { EnvironmentSun1 } from "../../icons/EnvironmentSun1";
import { Globe021 } from "../../icons/Globe021";
import { InterfaceChartLine2 } from "../../icons/InterfaceChartLine2";
import { NavigationBuilding021 } from "../../icons/NavigationBuilding021";
import { SmileyHappy1 } from "../../icons/SmileyHappy1";
import { Trophy1 } from "../../icons/Trophy1";
import { WarningShieldCheck6 } from "../../icons/WarningShieldCheck6";
import { Form } from "../../components/Form";
import { useTranslation } from "react-i18next";
import { useRef } from "react";
import { TeamBlock } from "../../components/TeamBlock/TeamBlock";
import { SuccessMessage } from "../../components/SuccessMessage";
import { FaqBlock } from "../../components/FaqBlock/FaqBlock";
import i18n from "i18next";

import "./style.css";
import { Carousel } from "../../components/Carousel/Carousel";

const teamList = [
  "developers",
  "design",
  "operation",
  "marketing",
  "compliance_development",
];

export const LandingPageXl = () => {
  const [teamBlockIsOpen, setTeamBlockIsOpen] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);
  const [faqOpen, setFaqOpen] = useState(false);

  const focusForm = useRef(null);
  const { t } = useTranslation("global");

  const handleFocusForm = () => {
    if (focusForm.current) {
      focusForm.current.focus();
    }
  };

  const handleBackendCall = (isOk, error) => {
    console.debug("receive handle backend call", isOk, error);
    setShowSuccess(true);
  };

  const videoLinks = {
    TopPresentation:
      i18n.language === "es"
        ? "https://www.youtube.com/embed/Ocl3s-esoXM?si=GBL5Q0VC2-kJc2xW"
        : "https://www.youtube.com/embed/t_wNHMKJlcY?si=wOmrX6xc_uajAAYb",
    ButtomLanding:
      i18n.language === "es"
        ? "https://www.youtube.com/embed/UYFSAQPcWUg?si=yszjN4_aEOh7Q_Ui"
        : "https://www.youtube.com/embed/UYFSAQPcWUg?si=yszjN4_aEOh7Q_Ui",
  };

  return (
    <div className="landing-page-XL">
      {showSuccess && <SuccessMessage onClose={() => setShowSuccess(false)} />}
      <div className="frame-36">
        <HeaderTimesharing
          className="header-timesharing-rent"
          headerDesktopTopNewLogoLogo="/img/logo-36.png"
          language="esp"
          type="caribs"
        />
        <div className="frame-37">
          <div className="frame-38">
            <p id="Project" className="text-wrapper-17">
              {t("main_heading")}
            </p>
            <div className="frame-39">
              <p className="DISFRUTA-LA-VIDA-EN">
                <span className="text-wrapper-18">
                  {t("header_card.title1")},{" "}
                </span>
                <span className="text-wrapper-19">
                  {t("header_card.title2")}
                </span>
              </p>
              <Button
                handleFocus={handleFocusForm}
                buttonClassName="button-4"
                className="design-component-instance-node"
                color="violet"
                device="desktop"
                size="l"
                state="default"
                text={t("header_card.button")}
              />
            </div>
          </div>
          <iframe
            className="video-wrapper"
            src={videoLinks.TopPresentation}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <div className="frame-40">
        <div className="frame-41">
          <div className="frame-42">
            <div id="properties" className="text-wrapper-20">
              {t("slider.title")}
            </div>
            <div className="frame-43">
              <div className="frame-44">
                <p className="text-wrapper-21">{t("slider.text1")}</p>
                <p className="text-wrapper-22">{t("slider.text2")}</p>
              </div>
            </div>
          </div>
          <div className="frame-45">
            <Carousel />
          </div>
        </div>
        <div className="frame-46">
          <div className="frame-47">
            <p className="text-wrapper-20">{t("welcome.title")}</p>
            <div className="frame-48">
              <div className="frame-49">
                <p className="text-wrapper-23">{t("welcome.subtitle")}</p>
                <p className="en-rent-onchain-tu">
                  <span className="text-wrapper-24">{t("welcome.text1")}</span>
                  <span className="text-wrapper-25">*</span>
                  <span className="text-wrapper-24"> {t("welcome.text2")}</span>
                </p>
              </div>
            </div>
          </div>

          <div className="fistForm">
            <Form
              formId="landing-page-caribs-xl-form1"
              backendCall={handleBackendCall}
            >
              {t("form.button")}
            </Form>
          </div>
        </div>
        <div id="Benefits" className="frame-55">
          <div className="text-wrapper-20">{t("benefits.title")}</div>

          <div className="card">
            <div className="frame-56">
              <div className="frame-57">
                <div className="text-wrapper-28">+</div>
                <p className="text-wrapper-29">{t("benefits.list.1")}</p>
              </div>
              <div className="frame-57">
                <div className="text-wrapper-28">+</div>
                <p className="div-13">
                  <span className="text-wrapper-30">
                    {t("benefits.list.2")}
                  </span>
                  <span className="text-wrapper-31">*</span>
                  <span className="text-wrapper-30">
                    {t("benefits.list.3")}
                  </span>
                </p>
              </div>
              <div className="frame-57">
                <div className="text-wrapper-28">+</div>

                <p className="text-wrapper-29">{t("benefits.list.4")}</p>
              </div>
              <div className="frame-57">
                <div className="text-wrapper-28">+</div>
                <p className="text-wrapper-29">{t("benefits.list.5")}</p>
              </div>
              <div className="frame-57">
                <div className="text-wrapper-28">+</div>
                <p className="div-13">
                  <span className="text-wrapper-30">
                    {t("benefits.list.6")}
                  </span>
                  <span className="text-wrapper-31">*</span>
                  <span className="text-wrapper-30">
                    {t("benefits.list.7")}
                  </span>
                </p>
              </div>
              <div className="frame-57">
                <div className="text-wrapper-28">+</div>
                <p className="text-wrapper-29">{t("benefits.list.8")}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="frame-58">
          <div className="frame-59">
            <div id="Properties" className="text-wrapper-20">
              {t("properties.title")}
            </div>
            <div className="frame-60">
              <div className="frame-58">
                <div className="disponibilidad">{t("properties.subtitle")}</div>
                <div className="frame-44">
                  <RealEstateCard
                    button={false}
                    className="design-component-instance-node-2"
                    divClassName="real-estate-card-instance"
                    frameClassName="real-estate-card-landing-desktop"
                    language="esp"
                    text="BRONZE"
                    text3={t("properties.card.type_of_property_apartment")}
                  />
                  <RealEstateCard
                    button={false}
                    className="design-component-instance-node-2"
                    frameClassName="real-estate-card-3"
                    groupClassName="real-estate-card-landing-desktop-instance"
                    language="esp"
                    overlapGroupClassName="real-estate-card-2"
                    text="SILVER"
                    text1="14.000 €"
                    text2="13.000 €"
                    text3={t("properties.card.type_of_property_loft")}
                    text4="*La fotografía puede no coincidir con el loft asignado pero, éste, tendrá las mismas características y estará publicado antes de la adquisición final"
                  />
                  <RealEstateCard
                    button={false}
                    className="design-component-instance-node-2"
                    frameClassName="real-estate-card-4"
                    groupClassName="real-estate-card-5"
                    language="esp"
                    overlapGroupClassName="real-estate-card-6"
                    text="GOLD"
                    text1="17.000 €"
                    text2="16.000 €"
                    text3={t("properties.card.type_of_property_house")}
                    text4="*La fotografía puede no coincidir con la casa asignada pero, ésta, tendrá las mismas características y estará publicada antes de la adquisición final."
                    text5="2"
                    text6="6"
                  />
                </div>
              </div>
              <div className="frame-58">
                <div className="disponibilidad">
                  {t("properties.subtitle2")}
                </div>
                <div className="frame-44">
                  <RealEstateCard
                    button
                    className="design-component-instance-node-2"
                    frameClassNameOverride="real-estate-card-7"
                    groupClassNameOverride="real-estate-card-8"
                    gym="/img/gym-3914460-1-2.svg"
                    handsHolding="/img/hands-holding-diamond-9844423-1-2.svg"
                    language="esp"
                    overlapGroupClassNameOverride="real-estate-card-9"
                    roomService="/img/room-service-5070430-1-2.svg"
                    text="VIP 1"
                    text1="30.000 €"
                    text2="25.000 €"
                    text3={t("properties.card.type_of_property_house_land")}
                    text4="*La fotografía puede no coincidir con la casa asignada pero, ésta, tendrá las mismas características y estará publicada antes de la adquisición final."
                    text5="2"
                    text6="6"
                  />
                  <RealEstateCard
                    button
                    className="design-component-instance-node-2"
                    frameClassNameOverride="real-estate-card-10"
                    groupClassNameOverride="real-estate-card-11"
                    gym="/img/gym-3914460-1-3.svg"
                    handsHolding="/img/hands-holding-diamond-9844423-1-3.svg"
                    language="esp"
                    overlapGroupClassNameOverride="real-estate-card-12"
                    roomService="/img/room-service-5070430-1-3.svg"
                    text="VIP 2"
                    text1="40.000 €"
                    text2="35.000 €"
                    text3={t("properties.card.type_of_property_house_land")}
                    text4="*La fotografía puede no coincidir con la casa asignada pero, ésta, tendrá las mismas características y estará publicada antes de la adquisición final."
                    text5="3"
                    text6="8-10"
                  />
                  <RealEstateCard
                    button
                    className="design-component-instance-node-2"
                    frameClassNameOverride="real-estate-card-13"
                    groupClassNameOverride="real-estate-card-8"
                    gym="/img/gym-3914460-1-4.svg"
                    handsHolding="/img/hands-holding-diamond-9844423-1-4.svg"
                    language="esp"
                    overlapGroupClassNameOverride="real-estate-card-9"
                    roomService="/img/room-service-5070430-1-4.svg"
                    text="VIP 3"
                    text1="50.000 €"
                    text2="45.000 €"
                    text3={t("properties.card.type_of_property_house_land")}
                    text4="*La fotografía puede no coincidir con la casa asignada pero, ésta, tendrá las mismas características y estará publicada antes de la adquisición final."
                    text5="4"
                    text6="10-12"
                  />
                </div>
              </div>
            </div>
          </div>
          <Form
            className="second"
            formId="landing-page-caribs-xl-form2"
            bigInputs={true}
            backendCall={handleBackendCall}
          >
            {t("form.button")}
          </Form>
        </div>

        <div id="Riviera" className="frame-44">
          <div className="frame-64">
            <p className="text-wrapper-20">{t("why_invest.title")}</p>
            <img
              className="unsplash-loiwhnwrq"
              alt="Unsplash"
              src="/img/unsplash-loi7whn1wrq-2.png"
            />
          </div>
          <div className="frame-65">
            <CardMiniDesktop
              bSquedaClassName="card-mini-desktop-3"
              className="card-mini-desktop-instance"
              divClassName="card-mini-desktop-3"
              frameClassName="card-mini-desktop-2"
              icon
              override={<Trophy1 className="icon-instance-node-2" />}
              text={t("why_invest.destination")}
              text1={t("why_invest.destination_text")}
            />
            <CardMiniDesktop
              bSquedaClassName="card-mini-desktop-5"
              className="card-mini-desktop-4"
              frameClassName="card-mini-desktop-2"
              icon
              override={
                <NavigationBuilding021 className="icon-instance-node-2" />
              }
              text={t("why_invest.trends")}
              text1={t("why_invest.trends_text")}
            />
            <CardMiniDesktop
              bSquedaClassName="card-mini-desktop-5"
              className="card-mini-desktop-6"
              frameClassName="card-mini-desktop-2"
              icon
              override={
                <WarningShieldCheck6 className="icon-instance-node-2" />
              }
              text={t("why_invest.security")}
              text1={t("why_invest.security_text")}
            />
            <CardMiniDesktop
              bSquedaClassName="card-mini-desktop-8"
              className="card-mini-desktop-7"
              divClassName="card-mini-desktop-3"
              frameClassName="card-mini-desktop-2"
              icon
              override={
                <InterfaceChartLine2
                  className="icon-instance-node-2"
                  color="white"
                />
              }
              text={t("why_invest.capital_gain")}
              text1={t("why_invest.capital_gain_text")}
            />
            <CardMiniDesktop
              bSquedaClassName="card-mini-desktop-8"
              className="card-mini-desktop-9"
              divClassName="card-mini-desktop-3"
              frameClassName="card-mini-desktop-2"
              icon
              override={<Globe021 className="icon-instance-node-2" />}
              text={t("why_invest.location")}
              text1={t("why_invest.location_text")}
            />
            <CardMiniDesktop
              bSquedaClassName="card-mini-desktop-5"
              className="card-mini-desktop-10"
              frameClassName="card-mini-desktop-2"
              icon
              override={<EnvironmentSun1 className="icon-instance-node-2" />}
              text={t("why_invest.climate")}
              text1={t("why_invest.climate_text")}
            />
            <CardMiniDesktop
              bSquedaClassName="card-mini-desktop-5"
              className="card-mini-desktop-6"
              frameClassName="card-mini-desktop-2"
              icon
              override={<SmileyHappy1 className="icon-instance-node-2" />}
              text={t("why_invest.cosmopolitan")}
              text1={t("why_invest.cosmopolitan_text")}
            />
            <CardMiniDesktop
              bSquedaClassName="card-mini-desktop-5"
              className="card-mini-desktop-11"
              frameClassName="card-mini-desktop-2"
              icon
              override={<SmileyHappy1 className="icon-instance-node-2" />}
              text={t("why_invest.leisure")}
              text1={t("why_invest.leisure_text")}
            />
          </div>
        </div>
        <div className="frame-66">
          <div className="frame-47">
            <p className="text-wrapper-20">{t("join_revolution.title")}</p>
            <div className="frame-48">
              <p className="text-wrapper-23">
                {" "}
                {t("join_revolution.subtitle")}
              </p>
            </div>
          </div>
          <Form
            className="firstVer"
            formId="landing-page-caribs-xl-form3"
            backendCall={handleBackendCall}
          >
            {t("form.button")}
          </Form>
        </div>
        <div className="frame-60">
          <div id="video" className="frame-67">
            <div className="text-wrapper-20">{t("promo_video.title")}</div>
            <p className="text-wrapper-22">{t("promo_video.subtitle")}</p>
          </div>
          <div className="player-instance-wrapper">
            <iframe
              width="560"
              height="315"
              src={videoLinks.ButtomLanding}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </div>
        <div id="Strengths" className="frame-49">
          <div className="text-wrapper-20">{t("strengths.title")}</div>
          <div className="frame-68">
            <img
              className="rectangle-4"
              alt="Rectangle"
              src="/img/rectangle-367.png"
            />
            <CardMiniDesktop
              className="card-mini-desktop-12"
              frameClassName="card-mini-desktop-2"
              icon={false}
              text={t("strengths.experience")}
              text1={t("strengths.experience_text")}
            />
            <CardMiniDesktop
              bSquedaClassNameOverride="card-mini-desktop-3"
              className="card-mini-desktop-13"
              divClassName="card-mini-desktop-3"
              frameClassName="card-mini-desktop-2"
              icon={false}
              text={t("strengths.diversity")}
              text1={t("strengths.diversity_text")}
            />
            <CardMiniDesktop
              className="card-mini-desktop-12"
              frameClassName="card-mini-desktop-2"
              icon={false}
              text={t("strengths.creativity")}
              text1={t("strengths.creativity_text")}
            />
            <CardMiniDesktop
              bSquedaClassNameOverride="card-mini-desktop-3"
              className="card-mini-desktop-14"
              divClassName="card-mini-desktop-3"
              frameClassName="card-mini-desktop-2"
              icon={false}
              text={t("strengths.scalability")}
              text1={t("strengths.scalability_text")}
            />
            <CardMiniDesktop
              className="card-mini-desktop-12"
              frameClassName="card-mini-desktop-2"
              icon={false}
              text={t("strengths.innovation")}
              text1={t("strengths.innovation_text")}
            />
            <CardMiniDesktop
              bSquedaClassNameOverride="card-mini-desktop-3"
              className="card-mini-desktop-13"
              divClassName="card-mini-desktop-3"
              frameClassName="card-mini-desktop-2"
              icon={false}
              text={t("strengths.loyalty")}
              text1={t("strengths.loyalty_text")}
            />
            <CardMiniDesktop
              className="card-mini-desktop-15"
              frameClassName="card-mini-desktop-2"
              icon={false}
              text={t("strengths.alliances")}
              text1={t("strengths.alliances_text")}
            />
            <CardMiniDesktop
              bSquedaClassNameOverride="card-mini-desktop-3"
              className="card-mini-desktop-14"
              divClassName="card-mini-desktop-3"
              frameClassName="card-mini-desktop-2"
              icon={false}
              text={t("strengths.liquidity")}
              text1={t("strengths.liquidity_text")}
            />
          </div>
        </div>

        <div className="frame-wrapper-2">
          <div
            id="team"
            className="frame-69"
            onClick={() => setTeamBlockIsOpen((prev) => !prev)}
          >
            <div className="text-wrapper-20">{t("team.title")}</div>
            <ArrowChevronRight
              className={teamBlockIsOpen ? "arrow-rotate" : ""}
            />
          </div>
          {teamBlockIsOpen && (
            <ul className="team-block-list">
              {teamList.map((team) => (
                <TeamBlock
                  key={crypto.randomUUID()}
                  team={t(`team.${team}`)}
                  id={team}
                />
              ))}
            </ul>
          )}
        </div>

        <div id="faq" className="frame-wrapper-2">
          <div className="frame-69" onClick={() => setFaqOpen((prev) => !prev)}>
            <div className="text-wrapper-20">{t("faq.title")}</div>
            <ArrowChevronRight className={faqOpen ? "arrow-rotate" : ""} />
          </div>
          {faqOpen && <FaqBlock />}
        </div>

        <div className="frame-47">
          <p className="text-wrapper-20">{t("pre_sale.title")}</p>
          <div id="presale" className="frame-70">
            <div className="frame-71">
              <p className="text-wrapper-32">{t("pre_sale.subtitle")}</p>
              <Timer
                className="timer-instance invisible"
                divClassName="timer-4"
                rectangleClassName="timer-4"
                rectangleClassNameOverride="timer-4"
                size="desktop"
                spanClassName="timer-2"
                spanClassName1="timer-2"
                spanClassName2="timer-3"
                spanClassName3="timer-2"
                spanClassName4="timer-3"
                spanClassName5="timer-2"
                spanClassName6="timer-3"
                spanClassNameOverride="timer-3"
                theme="light"
              />
            </div>
            <div className="frame-58">
              <div className="frame-72">
                <p className="text-wrapper-33">
                  {t("pre_sale.content_subtitle")}
                </p>
                <p className="text-wrapper-34">{t("pre_sale.content_text")}</p>
              </div>
              <Form
                ref={focusForm}
                className="third"
                formId="landing-page-caribs-xl-form4"
                backendCall={handleBackendCall}
              >
                {t("form.button")}
              </Form>
              <p className="text-wrapper-35">{t("pre_sale.caption")}</p>
            </div>
          </div>
        </div>
      </div>
      <FooterLandingPage language="esp" backendCall={handleBackendCall} />
    </div>
  );
};
