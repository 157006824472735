import { useEffect, useState } from "react";
import i18n from "../../services/i18next";
import "./style.css";

const LanguageBlock = () => {
  const [lang, setLang] = useState("en");

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [i18n, lang]);

  return (
    <div>
      <select
        className="language-select"
        value={lang}
        onChange={(e) => setLang(e.target.value)}
      >
        <option value="es">ESP</option>
        <option value="en">ENG</option>
      </select>
    </div>
  );
};

export default LanguageBlock;
