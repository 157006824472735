/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const NavigationCarAuto2 = ({ color = "white", className }) => {
  return (
    <svg
      className={`navigation-car-auto-2 ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 25 24"
      width="25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M3.5 11H4.54548M4.54548 11H20.4545M4.54548 11C4.55661 10.9485 4.56977 10.8975 4.58496 10.8471C4.62122 10.7268 4.67242 10.6111 4.77539 10.3795L6.32165 6.90039C6.62737 6.21253 6.78048 5.8684 7.0221 5.61621C7.23568 5.39329 7.49764 5.223 7.78809 5.11837C8.11667 5 8.49336 5 9.24609 5H15.7536C16.5063 5 16.8833 5 17.2119 5.11837C17.5024 5.223 17.764 5.39329 17.9775 5.61621C18.219 5.86823 18.3718 6.21208 18.6771 6.899L20.2296 10.3921C20.3289 10.6154 20.3794 10.729 20.415 10.8471C20.4301 10.8975 20.4433 10.9485 20.4545 11M4.54548 11C4.53302 11.0576 4.5231 11.1158 4.51572 11.1743C4.5 11.299 4.5 11.4257 4.5 11.6792V17M20.4545 11H21.5M20.4545 11C20.467 11.0576 20.477 11.1158 20.4844 11.1743C20.5 11.2982 20.5 11.4242 20.5 11.6746V17.0001M20.5 17.0001L16.5 17.0001M20.5 17.0001V17.9999C20.5 19.1045 19.6046 20 18.5 20C17.3954 20 16.5 19.1046 16.5 18V17.0001M16.5 17.0001L8.5 17M8.5 17H4.5M8.5 17V18C8.5 19.1046 7.60457 20 6.5 20C5.39543 20 4.5 19.1046 4.5 18V17"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

NavigationCarAuto2.propTypes = {
  color: PropTypes.string,
};
