/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { HeaderDesktop } from "../HeaderDesktop";
import { HeaderDesktopTop } from "../HeaderDesktopTop";
import "./style.css";

export const HeaderTimesharing = ({ type, language, className, headerDesktopTopNewLogoLogo = "/img/logo.png" }) => {
  return (
    <div className={`header-timesharing ${className}`}>
      <HeaderDesktopTop
        className="instance-node-2"
        language={language === "esp" ? "esp" : "eng"}
        newLogoLogo={headerDesktopTopNewLogoLogo}
        type={
          type === "caribs"
            ? "caribs"
            : type === "marketplace"
            ? "marketplace"
            : type === "lottery"
            ? "lottery"
            : "timesharing-rent"
        }
      />
      <HeaderDesktop
        className="instance-node-2"
        language={language === "esp" ? "esp" : "eng"}
        type={
          type === "caribs"
            ? "caribs"
            : type === "marketplace"
            ? "marketplace"
            : type === "lottery"
            ? "lottery"
            : "timesharing-rent"
        }
      />
    </div>
  );
};

HeaderTimesharing.propTypes = {
  type: PropTypes.oneOf(["lottery", "timesharing-rent", "marketplace", "caribs"]),
  language: PropTypes.oneOf(["esp", "eng"]),
  headerDesktopTopNewLogoLogo: PropTypes.string,
};
