import { useState } from "react";
import { useTranslation } from "react-i18next";

import { AddPlus } from "../../icons/AddPlus/AddPlus";

import "./style.css";

export function FaqBlockText({ question, answer }) {
  const [questionIsOpen, setQuestionIsOpen] = useState(false);

  function openQuestionHandler() {
    setQuestionIsOpen((prev) => !prev);
  }
  return (
    <li className="div-16" onClick={() => openQuestionHandler(question)}>
      <div className="frame-108">
        <div className="qu-aporta-rent">{question}</div>
        <AddPlus
          className={`addplus_icon ${questionIsOpen ? "plus-rotate" : ""}`}
        />
      </div>
      {questionIsOpen && <p className="rent-onchain-2">{answer}</p>}
    </li>
  );
}

export function FaqBlock() {
  const { t } = useTranslation("global");

  const questionsList = [
    {
      question: `${t("faq.q1")}`,
      answer: `${t("faq.a1")}`,
    },
    {
      question: `${t("faq.q2")}`,
      answer: `${t("faq.a2")}`,
    },
    {
      question: `${t("faq.q3")}`,
      answer: `${t("faq.a3")}`,
    },
    {
      question: `${t("faq.q4")}`,
      answer: `${t("faq.a4")}`,
    },
    {
      question: `${t("faq.q5")}`,
      answer: `${t("faq.a5")}`,
    },
    {
      question: `${t("faq.q6")}`,
      answer: `${t("faq.a6")}`,
    },
    {
      question: `${t("faq.q7")}`,
      answer: `${t("faq.a7")}`,
    },
    {
      question: `${t("faq.q8")}`,
      answer: `${t("faq.a8")}`,
    },
    {
      question: `${t("faq.q9")}`,
      answer: `${t("faq.a9")}`,
    },
    {
      question: `${t("faq.q10")}`,
      answer: `${t("faq.a10")}`,
    },
    {
      question: `${t("faq.q11")}`,
      answer: `${t("faq.a11")}`,
    },
  ];

  return (
    <ul className="faq-list">
      {questionsList.map((q) => (
        <div key={crypto.randomUUID()}>
          <FaqBlockText question={q.question} answer={q.answer} />
        </div>
      ))}
    </ul>
  );
}
