import PropTypes from "prop-types";
import React from "react";
import { ArrowCaretDownMd2 } from "../../icons/ArrowCaretDownMd2";
import { Button } from "../Button";
import { useTranslation } from "react-i18next";
import "./style.css";
import { Link } from "react-scroll";

export const HeaderDesktop = ({ type = "caribs", language, className }) => {
  const { t } = useTranslation("global");

  return (
    <div className={`header-desktop type-${type} ${className}`}>
      <div className="frame-6">
        {["lottery", "marketplace", "timesharing-rent"].includes(type) && (
          <div className="frame-7 invisible">
            {type === "timesharing-rent" && (
              <>
                <div className="div-2">
                  {language === "esp" && <>PÁGINA PRINCIPAL</>}

                  {language === "eng" && <>HOME</>}
                </div>
                <div className="div-2">
                  {language === "esp" && <>NUESTROS PROYECTOS</>}

                  {language === "eng" && <>REAL ESTATE PROJECTS</>}
                </div>
                <div className="div-2">
                  {language === "esp" && <>INVIERTE</>}

                  {language === "eng" && <>INVEST</>}
                </div>
                <div className="div-2">
                  {language === "esp" && <>NOTICIAS</>}

                  {language === "eng" && <>NEWS</>}
                </div>
                <div className="div-2">
                  {language === "esp" && <>NUESTRO EQUIPO</>}

                  {language === "eng" && <>OUR TEAM</>}
                </div>
                <div className="frame-8">
                  <div className="div-2">
                    {language === "esp" && <>AYUDA</>}

                    {language === "eng" && <>HELP</>}
                  </div>
                  <ArrowCaretDownMd2 className="arrow-caret-down-MD" />
                </div>
              </>
            )}

            {["lottery", "marketplace"].includes(type) && (
              <>
                <div className="div-3">
                  {language === "eng" && <>HOME</>}

                  {language === "esp" && <>PÁGINA PRINCIPAL</>}
                </div>
                <div className="div-3">
                  {type === "marketplace" && language === "eng" && <>CATALOG</>}

                  {type === "marketplace" && language === "esp" && (
                    <>CATÁLOGO</>
                  )}

                  {language === "eng" && type === "lottery" && <>RULES</>}

                  {type === "lottery" && language === "esp" && <>REGLAS</>}
                </div>
                <div className="div-3">
                  {type === "marketplace" && language === "eng" && <>AUCTION</>}

                  {type === "marketplace" && language === "esp" && <>SUBASTA</>}

                  {language === "eng" && type === "lottery" && (
                    <>WINNER’S TABLE</>
                  )}

                  {type === "lottery" && language === "esp" && (
                    <>MESA DE GANADORES</>
                  )}
                </div>
              </>
            )}

            {["lottery", "marketplace"].includes(type) && (
              <div className="div-3">
                {type === "lottery" && <>FAQ</>}

                {type === "marketplace" && language === "eng" && <>CREATE</>}

                {type === "marketplace" && language === "esp" && <>CREAR</>}
              </div>
            )}

            {type === "marketplace" && (
              <>
                <div className="div-3">FAQ</div>
                <div className="div-3">
                  {language === "eng" && <>STATS</>}

                  {language === "esp" && <>ESTADÍSTICAS</>}
                </div>
                <div className="div-3">
                  {language === "eng" && <>RESOURCES</>}

                  {language === "esp" && <>RECURSOS</>}
                </div>
              </>
            )}
          </div>
        )}

        {(type === "lottery" ||
          (language === "eng" && type === "timesharing-rent")) && (
          <div className="frame-9">
            <Button
              className={`${language === "esp" ? "class" : "button-instance"}`}
              color={type === "timesharing-rent" ? "violet" : "red-contour"}
              device="desktop"
              size="s"
              state="default"
              text={
                language === "esp"
                  ? "COMPROBACIÓN DE BILLETES"
                  : type === "timesharing-rent"
                  ? "ACCESS"
                  : "TICKET CHECK"
              }
            />
            {type === "lottery" && (
              <Button
                className={`${
                  language === "esp" ? "class-2" : "button-instance"
                }`}
                color={language === "esp" ? "violet" : "red"}
                device="desktop"
                size="s"
                state="default"
                text={language === "esp" ? "ACCESO" : "ACCESS"}
              />
            )}
          </div>
        )}

        {type === "caribs" && (
          <>
            <div className="div-3">
              {/* {language === "esp" && <>PROYECTO</>}
              {language === "eng" && <>PROJECT</>} */}
              <Link to="Project"> {t("header.project")}</Link>
            </div>
            <div className="div-3">
              {/* {language === "esp" && <>BENEFICIOS</>}
              {language === "eng" && <>BENEFITS</>} */}
              <Link to="Benefits"> {t("header.benefits")}</Link>
            </div>
            <div className="div-3">
              {/* {language === "esp" && <>INMUEBLES</>}
              {language === "eng" && <>PROPERTIES</>} */}
              <Link to="Properties"> {t("header.properties")}</Link>
            </div>
            <div className="div-3">
              <Link to="Riviera">{t("header.riviera_maya")}</Link>
            </div>
            <Link to="video">
              <div className="div-3">{t("header.videos")}</div>
            </Link>
            <div className="div-3">
              {/* {language === "esp" && <>FORTALEZAS</>}
              {language === "eng" && <>STRENGTHS</>} */}
              <Link to="Strengths">{t("header.strengths")}</Link>
            </div>
            <div className="div-3">
              {/* {language === "esp" && <>EQUIPO</>}
              {language === "eng" && <>TEAM</>} */}
              <Link to="team">{t("header.team")}</Link>
            </div>
            <div className="div-3">
              <Link to="faq">{t("header.faq")}</Link>
            </div>
            <div className="div-3">
              {/* {language === "esp" && <>PRE-VENTA</>}
              {language === "eng" && <>PRE-SALE</>} */}
              <Link to="presale">{t("header.pre_sale")}</Link>
            </div>
          </>
        )}

        {(type === "marketplace" ||
          (language === "esp" && type === "timesharing-rent")) && (
          <Button
            className="button-instance invisible"
            color={type === "marketplace" ? "blue" : "violet"}
            device="desktop"
            size="s"
            state="default"
            text={language === "eng" ? "ACCESS" : "ACCESO"}
          />
        )}
      </div>
    </div>
  );
};

HeaderDesktop.propTypes = {
  type: PropTypes.oneOf([
    "lottery",
    "timesharing-rent",
    "marketplace",
    "caribs",
  ]),
  language: PropTypes.oneOf(["esp", "eng"]),
};
