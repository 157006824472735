import React from "react";
import { useTranslation } from "react-i18next";
import "./style.css";

const SuccessMessage = ({ onClose }) => {
  const { t } = useTranslation("global");

  return (
    <div className="success-message-overlay">
      <div className="success-message-container">
        <div class="success-message success screen">
          <div class="success-message-1">
            <div class="frame-386 frame">
              <img
                class="menu-close_lg"
                src="/img/menu---close-lg@2x.png"
                alt="Menu / Close_LG"
                onClick={onClose}
              />
              <div class="frame-385 frame">
                <div class="frame-1458 frame">
                  <h1 class="su-solicitud-se-ha-e xltitlets">
                    {t("msg.success_send")}
                  </h1>
                  <img
                    class="warning-circle_-check"
                    src="/img/warning---circle-check-1@2x.png"
                    alt="Warning / Circle_Check"
                  />
                </div>
                <div class="frame-385-1">
                  <div class="button success" onClick={onClose}>
                    <div class="button-1 buttonl">{t("msg.accept")}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessMessage;