import { useState } from "react";
import { AddPlus } from "../../icons/AddPlus/AddPlus";
import "./style.css";
import { Linkedin } from "../../icons/Linkedin";
import { Global } from "../../icons/Global";

const teamList = [
  {
    teamName: "developers",
    members: [
      {
        photo: "/img/rectangle-470-83@2x.png",
        name: "Anton\nPolenyaka",
        role: "CEO/CTO",
        profileLink: "https://www.linkedin.com/in/antonpolenyaka/",
        dateOfJoining: "05.2023 - present",
      },
      {
        photo: "/img/rectangle-470-42@2x.png",

        name: "Lorenzo\nGabriel Vignolo",
        role: "Blockchain/Frontend",
        profileLink: "https://www.linkedin.com/in/lorenzo-vignolo/",
        dateOfJoining: "05.2023 - present",
      },
      {
        photo: "/img/rectangle-470-78@2x.png",

        name: "Juan David\nMarin Sanchez",
        role: "Blockchain",
        profileLink:
          "https://www.linkedin.com/in/juan-david-marin-sanchez-102a92b9/",
        dateOfJoining: "06.2023 - present",
      },
      // {
      //   photo: "/img/dummy-team-member.png",
      //   name: "Alexey\nGlazunov",
      //   role: "Backend",
      //   profileLink: "",
      //   dateOfJoining: "08.2023 - present",
      // },
      {
        photo: "/img/Nariman_Islamov.jpg",
        name: "Nariman\nIslamov",
        role: "Frontend",
        profileLink: "",
        dateOfJoining: "06.2023 - present",
      },
      {
        photo: "/img/rectangle-470-76@2x.png",
        name: "Antonio\nPalou",
        role: "Frontend",
        profileLink:
          "https://www.linkedin.com/in/toni-palou-de-comasema-7b4600258/",
        dateOfJoining: "06.2023 - present",
      },
      {
        photo: "/img/rectangle-470-74@2x.png",
        name: "Mansur\nVakhabov",
        role: "Frontend",
        profileLink: "https://www.linkedin.com/in/mansur-vakhabov-390792252/",
        dateOfJoining: "08.2023 - present",
      },
      {
        photo: "/img/rectangle-470-72@2x.png",
        name: "Viacheslav\nDemchenko",
        role: "Frontend",
        profileLink: "https://www.linkedin.com/in/viacheslavdemchenko",
        dateOfJoining: "08.2023 - 11.2023",
      },
      {
        photo: "/img/rectangle-470-70@2x.png",
        name: "Ivan\nKrasnoshchekov",
        role: "Frontend",
        profileLink: "https://www.linkedin.com/in/ivan-krasnoshchekov/",
        dateOfJoining: "09.2023 - present",
      },
      {
        photo: "/img/Kirill_Padalitsa.jpg",
        name: "Kirill\nPadalitsa",
        role: "Mobile IOS",
        profileLink: "",
        dateOfJoining: "08.2023 - present",
      },
      {
        photo: "/img/rectangle-470-40@2x.png",
        name: "Iñaki\nBalaguer",
        role: "Blockchain",
        profileLink: "https://www.linkedin.com/in/i%C3%B1aki-balaguer/",
        dateOfJoining: "06.2023 - 10.2023",
      },
      {
        photo: "/img/rectangle-470-33@2x.png",
        name: "Oleg\nBedrin",
        role: "Blockchain",
        profileLink: "https://www.linkedin.com/in/oleg-bedrin/",
        dateOfJoining: "06.2023 - 10.2023",
      },
    ],
  },
  {
    teamName: "design",
    members: [
      {
        photo: "/img/rectangle-470-63@2x.png",
        name: "Anastasia\nBorodaenko",
        role: "CDO",
        profileLink:
          "https://www.linkedin.com/in/anastasia-borodaenko-78965b296/",
        dateOfJoining: "05.2023 - present",
      },
      {
        photo: "/img/rectangle-470-28@2x.png",
        name: "Natalia\nProvorova",
        role: "WEB",
        profileLink: "",
        dateOfJoining: "05.2023 - present",
      },
      {
        photo: "/img/rectangle-470-18@2x.png",
        name: "Roman\nIvanov",
        role: "WEB",
        profileLink: "",
        dateOfJoining: "05.2023 - present",
      },
      {
        photo: "/img/rectangle-470-59@2x.png",
        name: "Kseniya\nRamanchyk",
        role: "WEB",
        profileLink: "https://www.linkedin.com/in/kseniya-ramanchyk",
        dateOfJoining: "05.2023 - present",
      },
      {
        photo: "/img/rectangle-470-57@2x.png",
        name: "Maxim\nSidorov",
        role: "Mobile",
        profileLink: "https://www.linkedin.com/in/maksim0sidorov/",
        dateOfJoining: "07.2023 - present",
      },
      {
        photo: "/img/Anatoli_Kozel.jpg",
        name: "Anatoli\nKozel",
        role: "Mobile",
        profileLink: "",
        dateOfJoining: "07.2023 - present",
      },
      {
        photo: "/img/rectangle-470-14@2x.png",
        name: "Marina\nKukharenko",
        role: "Branding",
        profileLink: "https://www.linkedin.com/in/marina-toochi/",
        dateOfJoining: "07.2023 - present",
      },
      {
        photo: "/img/rectangle-470-54@2x.png",
        name: "Julia\nVinogradova",
        role: "Branding",
        profileLink: "https://www.linkedin.com/in/yuliya-v-72ba80295/",
        dateOfJoining: "07.2023 - present",
      },
    ],
  },
  {
    teamName: "operation",
    members: [
      {
        photo: "/img/rectangle-470-52@2x.png",
        name: "Maxim\nNekrasov",
        role: "COO",
        profileLink: "https://www.linkedin.com/in/max-nekrasov-6676b9100/",
        dateOfJoining: "05.2023 - present",
      },
      {
        photo: "/img/rectangle-470-50@2x.png",
        name: "Antonio\nNavarro",
        role: "CRO",
        profileLink: "https://www.linkedin.com/in/antonionavarroroldan/",
        dateOfJoining: "07.2023 - present",
      },
      {
        photo: "/img/rectangle-470-4@2x.png",
        name: "Daniil\nLazukov",
        role: "Project manager",
        profileLink: "https://www.linkedin.com/in/lazoukov/",
        dateOfJoining: "07.2023 - present",
      },
    ],
  },
  {
    teamName: "marketing",
    members: [
      {
        photo: "/img/1648068792753.jpg",
        name: "Claudio\nMorgan",
        role: "CMO",
        profileLink: "https://www.linkedin.com/in/claudiomorgan/",
        dateOfJoining: "05.2023 - present",
      },
      {
        photo: "/img/photo_2023-12-12_12-05-10.jpg",
        name: "Andrea\nDominguez",
        role: "CM",
        profileLink:
          "https://www.linkedin.com/in/aluu-dom%C3%ADnguez-b8460999/",
        dateOfJoining: "06.2023 - present",
      },
      {
        photo: "/img/rectangle-470-47@2x.png",
        name: "MD Delwar\nHossain",
        role: "CM",
        profileLink: "https://www.linkedin.com/in/delwar-hossain-5b3424266",
        dateOfJoining: "09.2023 - present",
      },
      {
        photo: "/img/rectangle-470-45@2x.png",
        name: "Xenia\nNekrasova",
        role: "SEO/SMM",
        profileLink: "https://www.linkedin.com/in/xenia-nekrasova-778b25182/",
        dateOfJoining: "08.2023 - present",
      },
      {
        photo: "/img/1699653755706.jpg",
        name: "Walaa\nBoutaam",
        role: "Video",
        profileLink: "https://www.linkedin.com/in/walaa-boutaam-b53345227/",
        dateOfJoining: "11.2023 - present",
      },
      {
        photo: "/img/1675354489266.jpg",
        name: "Roger\nGalano",
        role: "Video",
        profileLink: "https://www.linkedin.com/in/roger-galano-7772901b3/",
        dateOfJoining: "11.2023 - present",
      },
    ],
  },
  {
    teamName: "compliance_development",
    members: [
      {
        photo: "/img/Lucero_Chargoy.jpg",
        name: "Lucero\nChargoy",
        role: "CFO",
        profileLink: "https://www.linkedin.com/in/lcjfinance/",
        dateOfJoining: "06.2023 - present",
      },
      {
        photo: "/img/technolaw.jpg",
        name: `“TECHO~LAW\nAdvisors”`,
        role: "CLO",
        profileLink: "https://www.technolaw.mx/",
        dateOfJoining: "08.2023 - present",
      },
    ],
  },
];

export function TeamBlock({ team, id }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <li
      className="team__name_content"
      onClick={() => setIsOpen((prev) => !prev)}
    >
      <div className="team__name_wrapper">
        <p className="team__name_title">{team}</p>
        <AddPlus className={`addplus_icon ${isOpen ? "icon-rotate" : ""}`} />
      </div>
      {isOpen && (
        <ul className="team__name_list" onClick={(e) => e.stopPropagation()}>
          {teamList
            .find((team) => team.teamName === id)
            ?.members.map((member) => (
              <li key={member.name}>
                <a
                  href={member.profileLink ? member.profileLink : "#"}
                  target={member.profileLink ? "_blank" : "_self"}
                  onClick={(e) => {
                    if (!member.profileLink) e.preventDefault();
                  }}
                  className={
                    !member.profileLink ? "member__link_no-cursor" : ""
                  }
                >
                  <img
                    src={member.photo}
                    alt={member.name}
                    className="member__photo"
                    loading="lazy"
                  />
                  <div className="member__name-block">
                    <p className="member__name">
                      {member.name.split("\n").map((namePart, index) => (
                        <div key={index}>{namePart}</div>
                      ))}
                    </p>

                    {member.profileLink &&
                      (member.profileLink.startsWith(
                        "https://www.technolaw.mx/"
                      ) ? (
                        <Global className="linkedin_icon" />
                      ) : (
                        <Linkedin className="linkedin_icon" />
                      ))}
                  </div>
                  <p className="member__role">{member.role}</p>
                  <p className="member__date">{member.dateOfJoining}</p>
                </a>
              </li>
            ))}
        </ul>
      )}
    </li>
  );
}
