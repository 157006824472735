/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const InterfaceChartLine2 = ({ color = "white", className }) => {
  return (
    <svg
      className={`interface-chart-line-2 ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M4 20.0003V22.4C4 23.8935 4 24.6397 4.29065 25.2101C4.54631 25.7119 4.95396 26.1206 5.45573 26.3763C6.0256 26.6667 6.77199 26.6667 8.26255 26.6667H28.0003M4 20.0003V6.66666M4 20.0003L9.13786 15.7187L9.14211 15.7153C10.0716 14.9408 10.5372 14.5528 11.0421 14.3952C11.6385 14.209 12.2809 14.2384 12.8581 14.4779C13.3473 14.6808 13.7763 15.1098 14.6343 15.9678L14.643 15.9764C15.5143 16.8478 15.9511 17.2846 16.4482 17.4871C17.0365 17.7268 17.6914 17.7474 18.2943 17.5474C18.8054 17.3779 19.2723 16.9701 20.2057 16.1533L28 9.33332"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </svg>
  );
};

InterfaceChartLine2.propTypes = {
  color: PropTypes.string,
};
