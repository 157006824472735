import React, { useEffect, useState } from "react";
import AliceCarousel from "react-alice-carousel";

import { ArrowChevronLeft1 } from "../../icons/ArrowChevronLeft1";
import { ArrowChevronRight1 } from "../../icons/ArrowChevronRight1";

import "react-alice-carousel/lib/alice-carousel.css";
import "./style.css";

export const Carousel = () => {
  const [screen, setScreen] = useState(getScreenType());

  const handleDragStart = (e) => e.preventDefault();

  useEffect(() => {
    const handleResize = () => {
      setScreen(getScreenType());
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function getScreenType() {
    return window.innerWidth < 1024 ? "1" : "2";
  }

  const items = [
    <img
      src={`/img/rajiv-perera-8qjrlmRj7K0-unsplash${screen}.png`}
      onDragStart={handleDragStart}
      role="presentation"
    />,
    <img
      src={`/img/alisa-matthews-HcN8TgNnyW4-unsplash${screen}.png`}
      onDragStart={handleDragStart}
      role="presentation"
    />,
    <img
      src={`/img/suzanne-rushton-p5JVfOG7RDQ-unsplash${screen}.png`}
      onDragStart={handleDragStart}
      role="presentation"
    />,
    <img
      src={`/img/rajiv-perera-R2Ex9FuOq9E-unsplash${screen}.png`}
      onDragStart={handleDragStart}
      role="presentation"
    />,
    <img
      src={`/img/woman-on-a-beach-near-ruins-in-tulum-mexico-picjumbo-com${screen}.png`}
      onDragStart={handleDragStart}
      role="presentation"
    />,
  ];

  return (
    <div className="carousel-wrapper">
      {screen === "2" && (
        <AliceCarousel
          renderPrevButton={ArrowChevronLeft1}
          mouseTracking
          items={items}
          disableDotsControls
          animationDuration={1000}
          infinite
          renderNextButton={ArrowChevronRight1}
        />
      )}
      {screen === "1" && (
        <AliceCarousel
          mouseTracking
          autoPlay
          items={items}
          disableButtonsControls
          animationDuration={1000}
          autoPlayInterval={2500}
          infinite
        />
      )}
    </div>
  );
};
