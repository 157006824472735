import PropTypes from "prop-types";
import React from "react";
import { ArrowCaretDownMd } from "../../icons/ArrowCaretDownMd";
import { ArrowCaretDownMd2 } from "../../icons/ArrowCaretDownMd2";
import { InterfaceSearchMagnifyingGlass } from "../../icons/InterfaceSearchMagnifyingGlass";
import { NewLogo } from "../NewLogo";
import "./style.css";
import LanguageBlock from "../LanguageBlock/LanguageBlock";

export const HeaderDesktopTop = ({
  type,
  language,
  className,
  newLogoLogo = "/img/logo.png",
}) => {
  return (
    <div className={`header-desktop-top ${className}`}>
      <div className="frame">
        {[
          "academy",
          "defi",
          "lottery",
          "marketplace",
          "timesharing-rent",
          "tokenization",
        ].includes(type) && (
          <div className="frame-wrapper">
            <div className="div">
              <NewLogo
                className="new-logo-instance"
                logo={type === "timesharing-rent" ? "/img/logo.png" : undefined}
                logo1={type === "lottery" ? "/img/logo-6.png" : undefined}
                logo2={type === "marketplace" ? "/img/logo-4.png" : undefined}
                logo3={type === "academy" ? "/img/logo-16.png" : undefined}
                logo4={type === "defi" ? "/img/logo-18.png" : undefined}
                logo5={type === "tokenization" ? "/img/logo-20.png" : undefined}
                size="desktop"
                type={
                  type === "lottery"
                    ? "lottery"
                    : type === "marketplace"
                    ? "marketplace"
                    : type === "academy"
                    ? "academy"
                    : type === "defi"
                    ? "defi"
                    : type === "tokenization"
                    ? "tokenization"
                    : "timesharing-rent"
                }
              />
              <div className="frame-2">
                <div className="more-wrapper">
                  <div className="more">
                    {language === "eng" && <>More</>}

                    {language === "esp" && <>Más</>}
                  </div>
                </div>
                <ArrowCaretDownMd className="instance-node" />
              </div>
            </div>
          </div>
        )}

        {type === "caribs" && (
          <NewLogo
            className="new-logo-instance"
            logo={newLogoLogo}
            size="desktop"
            type="caribs"
          />
        )}

        <div className={`frame-3 ${type}`}>
          <div className="frame-4 invisible">
            <div className="eng-2">
              {language === "eng" && <>ENG</>}

              {language === "esp" && <>ESP</>}
            </div>
            <ArrowCaretDownMd2 className="instance-node" />
          </div>
          <LanguageBlock />
          {[
            "academy",
            "defi",
            "lottery",
            "marketplace",
            "timesharing-rent",
            "tokenization",
          ].includes(type) && (
            <div className="frame-5">
              <div className="component">
                <div className="text-wrapper">€</div>
              </div>
              <div className="div-wrapper">
                <div className="text-wrapper-2">$</div>
              </div>
            </div>
          )}

          <InterfaceSearchMagnifyingGlass className="instance-node invisible" />
        </div>
      </div>
    </div>
  );
};

HeaderDesktopTop.propTypes = {
  type: PropTypes.oneOf([
    "defi",
    "academy",
    "marketplace",
    "timesharing-rent",
    "caribs",
    "lottery",
    "tokenization",
  ]),
  language: PropTypes.oneOf(["esp", "eng"]),
  newLogoLogo: PropTypes.string,
};
