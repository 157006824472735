import React from "react";

export const Linkedin = ({ className }) => {
  return (
    <svg
      className={`linkedin-icon ${className}`}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.14541 20.7665H17.8602C19.4669 20.7665 20.7693 19.4643 20.7693 17.857V4.14264C20.7693 2.53578 19.4668 1.23317 17.8602 1.23317H4.14541C2.53869 1.23317 1.23594 2.53592 1.23594 4.14264V17.857C1.23594 19.4641 2.53862 20.7665 4.14541 20.7665Z"
        stroke="white"
        strokeWidth="1.8"
      />
      <path d="M6.16406 15.7539H8.37175V8.6221H6.16406V15.7539Z" fill="white" />
      <path
        d="M7.25893 7.68848C7.97998 7.68848 8.56429 7.09877 8.56429 6.37187C8.56429 5.64542 7.97998 5.05617 7.25893 5.05617C6.53743 5.05617 5.95312 5.64542 5.95312 6.37187C5.95312 7.09877 6.53743 7.68848 7.25893 7.68848Z"
        fill="white"
      />
      <path
        d="M11.899 12.0101C11.899 11.0075 12.3605 10.4101 13.2444 10.4101C14.0558 10.4101 14.4458 10.9836 14.4458 12.0101C14.4458 13.037 14.4458 15.7539 14.4458 15.7539H16.6432C16.6432 15.7539 16.6432 13.1486 16.6432 11.2382C16.6432 9.32786 15.5605 8.40439 14.0482 8.40439C12.5355 8.40439 11.899 9.58246 11.899 9.58246V8.6221H9.78125V15.7539H11.899C11.899 15.7539 11.899 13.1085 11.899 12.0101Z"
        fill="white"
      />
    </svg>
  );
};
