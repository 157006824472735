import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Button = ({
  color,
  state,
  size,
  device,
  className,
  buttonClassName,
  text = "BUTTON",
  handleFocus,
}) => {
  return (
    <button
      onClick={handleFocus}
      className={`button device-${device} ${size} ${color} ${state} ${className}`}
    >
      <div className={`div-4 ${buttonClassName}`}>
        {((color === "red-contour" && size === "m") ||
          (color === "violet-contour" && size === "m") ||
          size === "XL" ||
          size === "l" ||
          size === "s") && <>{text}</>}

        {((color === "blue" && size === "m") ||
          (color === "green" && size === "m") ||
          (color === "red" && size === "m") ||
          (color === "violet" && size === "m") ||
          (color === "white" && size === "m")) && (
          <button className="text-wrapper-3">{text}</button>
        )}
      </div>
    </button>
  );
};

Button.propTypes = {
  color: PropTypes.oneOf([
    "white",
    "rose",
    "violet",
    "violet-contour",
    "blue",
    "green",
    "red",
    "red-contour",
  ]),
  state: PropTypes.oneOf(["pressed", "hover", "inactive", "default"]),
  size: PropTypes.oneOf(["l", "m", "XL", "s"]),
  device: PropTypes.oneOf(["mini", "desktop", "max", "mobile"]),
  text: PropTypes.string,
};
