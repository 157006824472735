/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Links = ({
  state,
  size,
  weight,
  color,
  className,
  text = "Link",
  line = "/img/line-13-4.svg",
  img = "/img/line-12-31.svg",
  divClassName,
}) => {
  return (
    <div className={`links state-1-${state} ${size} ${weight} ${className}`}>
      <div className={`link color-1-${color} size-${size} weight-${weight} ${divClassName}`}>{text}</div>
      <img
        className="line-2"
        alt="Line"
        src={
          color === "black" && size === "mobile-s" && weight === "reg"
            ? "/img/line-12-23.svg"
            : size === "mobile-s" && color === "dark-gray" && weight === "reg"
            ? "/img/line-12-24.svg"
            : color === "gray" && size === "mobile-s" && weight === "reg"
            ? "/img/line-12-25.svg"
            : color === "white" && size === "mobile-s" && weight === "reg"
            ? "/img/line-12-26.svg"
            : color === "violet" && size === "mobile-s" && weight === "reg"
            ? "/img/line-12-27.svg"
            : color === "blue" && size === "mobile-s" && weight === "reg"
            ? "/img/line-12-28.svg"
            : size === "mobile-s" && weight === "reg" && color === "dark-green"
            ? "/img/line-12-29.svg"
            : size === "mobile-s" && weight === "reg" && color === "red"
            ? "/img/line-12-30.svg"
            : color === "black" && size === "mobile-s" && weight === "med"
            ? img
            : size === "mobile-s" && color === "dark-gray" && weight === "med"
            ? "/img/line-12-32.svg"
            : color === "gray" && size === "mobile-s" && weight === "med"
            ? "/img/line-12-33.svg"
            : color === "white" && size === "mobile-s" && weight === "med"
            ? "/img/line-12-34.svg"
            : color === "violet" && size === "mobile-s" && weight === "med"
            ? "/img/line-12-35.svg"
            : color === "blue" && size === "mobile-s" && weight === "med"
            ? "/img/line-12-36.svg"
            : size === "mobile-s" && weight === "med" && color === "dark-green"
            ? "/img/line-12-37.svg"
            : size === "mobile-s" && weight === "med" && color === "red"
            ? "/img/line-12-38.svg"
            : size === "mobile-m" && color === "black"
            ? "/img/line-12-39.svg"
            : size === "mobile-m" && color === "dark-gray"
            ? "/img/line-12-40.svg"
            : size === "mobile-m" && color === "gray"
            ? "/img/line-12-41.svg"
            : size === "mobile-m" && color === "white"
            ? "/img/line-12-42.svg"
            : size === "mobile-m" && color === "violet"
            ? "/img/line-12-43.svg"
            : size === "mobile-m" && color === "blue"
            ? "/img/line-12-44.svg"
            : size === "mobile-m" && color === "dark-green"
            ? "/img/line-12-45.svg"
            : size === "mobile-m" && color === "red"
            ? "/img/line-12-46.svg"
            : color === "black" && size === "mobile-l"
            ? "/img/line-12-47.svg"
            : color === "dark-gray" && size === "mobile-l"
            ? "/img/line-12-48.svg"
            : color === "gray" && size === "mobile-l"
            ? "/img/line-12-49.svg"
            : color === "white" && size === "mobile-l"
            ? "/img/line-12-50.svg"
            : color === "violet" && size === "mobile-l"
            ? "/img/line-12-51.svg"
            : color === "blue" && size === "mobile-l"
            ? "/img/line-12-52.svg"
            : color === "dark-green" && size === "mobile-l"
            ? "/img/line-12-53.svg"
            : color === "red" && size === "mobile-l"
            ? "/img/line-12-54.svg"
            : color === "black" && weight === "bold" && size === "mobile-s"
            ? "/img/line-12-79.svg"
            : size === "mobile-s" && weight === "bold" && color === "dark-gray"
            ? "/img/line-12-80.svg"
            : color === "gray" && weight === "bold" && size === "mobile-s"
            ? "/img/line-12-81.svg"
            : size === "mobile-s" && color === "white" && weight === "bold"
            ? "/img/line-12-82.svg"
            : color === "violet" && weight === "bold" && size === "mobile-s"
            ? "/img/line-12-83.svg"
            : color === "blue" && weight === "bold" && size === "mobile-s"
            ? "/img/line-12-84.svg"
            : weight === "bold" && size === "mobile-s" && color === "dark-green"
            ? "/img/line-12-85.svg"
            : weight === "bold" && size === "mobile-s" && color === "red"
            ? "/img/line-12-86.svg"
            : size === "desktop-m" && color === "black" && weight === "reg"
            ? line
            : size === "desktop-m" && weight === "reg" && color === "violet"
            ? "/img/line-13-5.svg"
            : size === "desktop-m" && color === "white" && weight === "reg"
            ? "/img/line-13-6.svg"
            : size === "desktop-m" && weight === "reg" && color === "blue"
            ? "/img/line-13-7.svg"
            : size === "desktop-m" && weight === "reg" && color === "dark-green"
            ? "/img/line-13-8.svg"
            : size === "desktop-m" && weight === "reg" && color === "red"
            ? "/img/line-13-9.svg"
            : size === "desktop-m" && color === "gray" && weight === "reg"
            ? "/img/line-13-10.svg"
            : size === "desktop-m" && color === "dark-gray" && weight === "reg"
            ? "/img/line-13-11.svg"
            : size === "desktop-m" && color === "black" && weight === "med"
            ? "/img/line-12-22.svg"
            : size === "desktop-m" && weight === "med" && color === "violet"
            ? "/img/line-12-104.svg"
            : size === "desktop-m" && color === "white" && weight === "med"
            ? "/img/line-12-105.svg"
            : size === "desktop-m" && weight === "med" && color === "blue"
            ? "/img/line-12-106.svg"
            : size === "desktop-m" && weight === "med" && color === "dark-green"
            ? "/img/line-12-107.svg"
            : size === "desktop-m" && weight === "med" && color === "red"
            ? "/img/line-12-108.svg"
            : size === "desktop-m" && color === "gray" && weight === "med"
            ? "/img/line-12-109.svg"
            : size === "desktop-m" && color === "dark-gray" && weight === "med"
            ? "/img/line-12-110.svg"
            : size === "desktop-m" && color === "black" && weight === "bold"
            ? "/img/line-17.svg"
            : size === "desktop-m" && weight === "bold" && color === "violet"
            ? "/img/line-17-1.svg"
            : size === "desktop-m" && color === "white" && weight === "bold"
            ? "/img/line-17-2.svg"
            : size === "desktop-m" && weight === "bold" && color === "blue"
            ? "/img/line-17-3.svg"
            : size === "desktop-m" && weight === "bold" && color === "dark-green"
            ? "/img/line-17-4.svg"
            : size === "desktop-m" && weight === "bold" && color === "red"
            ? "/img/line-17-5.svg"
            : size === "desktop-m" && color === "gray" && weight === "bold"
            ? "/img/line-17-6.svg"
            : size === "desktop-m" && weight === "bold" && color === "dark-gray"
            ? "/img/line-17-7.svg"
            : color === "black" && size === "desktop-l"
            ? "/img/line-13-20.svg"
            : color === "violet" && size === "desktop-l"
            ? "/img/line-13-21.svg"
            : size === "desktop-l" && color === "white"
            ? "/img/line-13-22.svg"
            : color === "blue" && size === "desktop-l"
            ? "/img/line-13-23.svg"
            : size === "desktop-l" && color === "dark-green"
            ? "/img/line-13-24.svg"
            : size === "desktop-l" && color === "red"
            ? "/img/line-13-25.svg"
            : color === "gray" && size === "desktop-l"
            ? "/img/line-13-26.svg"
            : size === "desktop-l" && color === "dark-gray"
            ? "/img/line-13-27.svg"
            : undefined
        }
      />
    </div>
  );
};

Links.propTypes = {
  state: PropTypes.oneOf(["pressed", "defolt", "hover"]),
  size: PropTypes.oneOf(["mobile-s", "desktop-m", "desktop-l", "mobile-m", "mobile-l"]),
  weight: PropTypes.oneOf(["med", "semi", "reg", "bold"]),
  color: PropTypes.oneOf(["dark-green", "white", "dark-gray", "black", "gray", "violet", "blue", "red"]),
  text: PropTypes.string,
  line: PropTypes.string,
  img: PropTypes.string,
};
