/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const ArrowCaretLeftSm4 = ({ color = "white", className }) => {
  return null;
  // <svg
  //   className={`arrow-caret-left-sm-4 ${className}`}
  //   fill="none"
  //   height="40"
  //   viewBox="0 0 40 40"
  //   width="40"
  //   xmlns="http://www.w3.org/2000/svg"
  // >
  //   <path
  //     className="path"
  //     d="M21.6666 25L16.6666 20L21.6666 15"
  //     stroke={color}
  //     strokeLinecap="round"
  //     strokeLinejoin="round"
  //     strokeWidth="2"
  //   />
  // </svg>
};

ArrowCaretLeftSm4.propTypes = {
  color: PropTypes.string,
};
