/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const EnvironmentLeaf = ({ color = "#6957DA", className }) => {
  return (
    <svg
      className={`environment-leaf ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 25 24"
      width="25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M7.5791 17.0806C14.6502 21.3232 20.307 15.6663 19.5999 5.05981C8.99352 4.35269 3.33692 10.0097 7.5791 17.0806ZM7.5791 17.0806C7.57902 17.0805 7.57918 17.0807 7.5791 17.0806ZM7.5791 17.0806L5.75 18.909M7.5791 17.0806L11.4069 13.2522"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

EnvironmentLeaf.propTypes = {
  color: PropTypes.string,
};
