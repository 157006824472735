import PropTypes from "prop-types";
import React from "react";
import { ArrowCaretLeftSm4 } from "../../icons/ArrowCaretLeftSm4";
import { ArrowCaretRightSm5 } from "../../icons/ArrowCaretRightSm5";
import { Diamond } from "../../icons/Diamond";
import { EnvironmentLeaf } from "../../icons/EnvironmentLeaf";
import { InterfaceHeart01 } from "../../icons/InterfaceHeart01";
import { NavigationCarAuto2 } from "../../icons/NavigationCarAuto2";
import { Umbrella } from "../../icons/Umbrella";
import { WarningShieldCheck1 } from "../../icons/WarningShieldCheck1";
import { Button } from "../Button";
import { Status } from "../Status";
import { useTranslation } from "react-i18next";
import "./style.css";

export const RealEstateCard = ({
  language,
  button,
  className,
  frameClassName,
  divClassName,
  text = "TULUM 2",
  groupClassName,
  overlapGroupClassName,
  text1 = "11.000 €",
  text2 = "10.000 €",
  text3 = "Apartamento",
  text4 = "*La fotografía puede no coincidir con el apartamento asignado pero, éste, tendrá las mismas características y estará publicado antes de la adquisición final.",
  text5 = "1",
  text6 = "4",
  frameClassNameOverride,
  groupClassNameOverride,
  overlapGroupClassNameOverride,
  gym = "/img/gym-3914460-1.svg",
  handsHolding = "/img/hands-holding-diamond-9844423-1.svg",
  roomService = "/img/room-service-5070430-1.svg",
}) => {
  const { t } = useTranslation("global");

  return (
    <div className={`real-estate-card ${className}`}>
      <div
        className={`frame-11 ${
          button ? frameClassNameOverride : !button ? frameClassName : undefined
        }`}
      >
        <ArrowCaretRightSm5 className="arrow-caret-right-SM" />
        <ArrowCaretLeftSm4 className="arrow-caret-left-SM" color="#A8A8A8" />
        <div className="slider">
          <div className="ellipse" />
          <div className="ellipse-2" />
        </div>
      </div>
      <div className="frame-12">
        <div className="frame-13">
          <div className="frame-14">
            <div className={`TULUM ${!button ? divClassName : undefined}`}>
              {text}
            </div>
            <div className="frame-15">
              <div
                className={`group ${
                  button
                    ? groupClassNameOverride
                    : !button
                    ? groupClassName
                    : undefined
                }`}
              >
                <div
                  className={`overlap-group ${
                    button
                      ? overlapGroupClassNameOverride
                      : !button
                      ? overlapGroupClassName
                      : undefined
                  }`}
                >
                  <div className="element">{text1}</div>
                  <img className="line" alt="Line" src="/img/line-41.svg" />
                </div>
              </div>
            </div>
          </div>
          <div className="frame-14">
            <Status
              className="status-instance"
              language="esp"
              size="desktop"
              status="finansed"
              // text={language === "eng" ? "Pre-Sale" : "Pre-Venta"}
              text={t("properties.card.status")}
            />
            <div className="frame-15">
              <div className="element-2">{text2}</div>
            </div>
          </div>
        </div>
        <div className="frame-16">
          <div className="frame-14">
            <div className="div-6">
              {/* {language === "esp" && <>País</>}
              {language === "eng" && <>Country</>} */}
              {t("properties.card.country")}
            </div>
            <div className="div-7">
              {/* {language === "esp" && <>México</>}
              {language === "eng" && <>Mexico</>} */}
              {t("properties.card.country_mexico")}
            </div>
          </div>
          <div className="frame-14">
            <div className="div-6">
              {/* {language === "esp" && <>Lugar</>}
              {language === "eng" && <>Place</>} */}
              {t("properties.card.place")}
            </div>
            <div className="div-7">
              {/* {language === "esp" && <>Caribe (Riviera Maya)</>}
              {language === "eng" && <> Caribbean (Riviera Maya)</>} */}
              {t("properties.card.place_riviera_maya")}
            </div>
          </div>
          <div className="frame-14">
            <div className="div-6">
              {/* {language === "esp" && <>Habitaciones</>}
              {language === "eng" && <>Rooms</>} */}
              {t("properties.card.rooms")}
            </div>
            <div className="div-7">{text5}</div>
          </div>
          <div className="frame-14">
            <div className={`hu-spedes ${language}`}>
              {/* {language === "esp" && <>Huéspedes</>}
              {language === "eng" && <>Sleeping places</>} */}
              {t("properties.card.sleeping_places")}
            </div>
            <div className="div-7">{text6}</div>
          </div>
          <div className="frame-14">
            <div className={`tipo-de-inmueble language-${language}`}>
              {/* {language === "esp" && <>Tipo de inmueble</>}
              {language === "eng" && <>Type of property</>} */}
              {t("properties.card.type_of_property")}
            </div>
            <div className={`apartamento language-0-${language}`}>
              {/* {language === "esp" && <>{text3}</>}
              {language === "eng" && <>Apartment</>} */}
              {text3}
            </div>
          </div>
        </div>
        {button && (
          <div className="frame-14">
            <WarningShieldCheck1 className="instance-node-4" />
            <InterfaceHeart01 className="instance-node-4" />
            <img
              className="img-2"
              alt="Gym"
              src={language === "eng" ? "/img/gym-3914460-1-1.svg" : gym}
            />
            <img
              className="img-2"
              alt="Hands holding"
              src={
                language === "eng"
                  ? "/img/hands-holding-diamond-9844423-1-1.svg"
                  : handsHolding
              }
            />
            <img
              className="img-2"
              alt="Room service"
              src={
                language === "eng"
                  ? "/img/room-service-5070430-1-1.svg"
                  : roomService
              }
            />
            <EnvironmentLeaf className="instance-node-4" color="#6957DA" />
            <NavigationCarAuto2 className="instance-node-4" color="#6957DA" />
            <Umbrella className="instance-node-4" />
            <Diamond className="instance-node-4" color="#6957DA" />
          </div>
        )}

        <div className="la-fotograf-a-puede">
          {/* {language === "esp" && <p className="text-wrapper-70">{text4}</p>}
          {language === "eng" && (
            <p className="text-wrapper-70">
              *The photograph may not coincide with the assigned apartment, but
              it will have the same characteristics and will be published before
              the final purchase.
            </p>
          )} */}
          {t("properties.card.note")}
        </div>
      </div>
      {/* {button && (
        <Button
          className="button-2"
          color="violet"
          device="desktop"
          size="l"
          state="default"
          // text={language === "eng" ? "MORE INFORMATION" : "MÁS INFORMACIÓN"}
          text={t("properties.card.button")}
        />
      )} */}
    </div>
  );
};

RealEstateCard.propTypes = {
  language: PropTypes.oneOf(["esp", "eng"]),
  button: PropTypes.bool,
  text: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
  text3: PropTypes.string,
  text4: PropTypes.string,
  text5: PropTypes.string,
  text6: PropTypes.string,
  gym: PropTypes.string,
  handsHolding: PropTypes.string,
  roomService: PropTypes.string,
};
