/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { InterfaceHeart012 } from "../../icons/InterfaceHeart012";
import "./style.css";

export const InterfaceHeart = () => {
  return <InterfaceHeart012 className="interface-heart-01-2" />;
};
