import PropTypes from "prop-types";
import React from "react";
import { InterfaceChartLine2 } from "../../icons/InterfaceChartLine2";
import "./style.css";

export const CardMiniDesktop = ({
  icon,
  className,
  frameClassName,
  bSquedaClassName,
  text = "BÚSQUEDA",
  override = <InterfaceChartLine2 className="interface-chart-line-2" color="#1A1A1C" />,
  divClassName,
  text1 = "Seleccionamos las mejores oportunidades para comprar.",
  bSquedaClassNameOverride,
}) => {
  return (
    <div className={`card-mini-desktop ${className}`}>
      <div className={`frame-17 icon-${icon} ${frameClassName}`}>
        <div className={`b-squeda ${bSquedaClassNameOverride}`}>
          {!icon && <>{text}</>}

          {icon && (
            <>
              <div className={`b-squeda-2 ${bSquedaClassName}`}>{text}</div>
              {override}
            </>
          )}
        </div>
        <p className={`seleccionamos-las ${divClassName}`}>{text1}</p>
      </div>
    </div>
  );
};

CardMiniDesktop.propTypes = {
  icon: PropTypes.bool,
  text: PropTypes.string,
  text1: PropTypes.string,
};
