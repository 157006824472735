/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Trophy1 = ({ className }) => {
  return (
    <svg
      className={`trophy-1 ${className}`}
      width="32"
      height="32"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3002 11.9985C16.242 11.9805 18 9.366 18 7.125C18 5.868 17.1112 4.81725 15.93 4.5615C16.1632 3.9 16.3395 3.26325 16.4565 2.69475C16.5915 2.03775 16.4235 1.36125 15.9967 0.83775C15.5625 0.30525 14.919 0 14.2327 0H3.76725C3.08025 0 2.4375 0.30525 2.00325 0.83775C1.5765 1.3605 1.4085 2.03775 1.5435 2.69475C1.6605 3.264 1.83675 3.9 2.07 4.5615C0.88875 4.81725 0 5.868 0 7.125C0 9.366 1.758 11.9797 6.69975 11.9985C6.72225 12.1567 6.75 12.3142 6.75 12.4777V15C6.75 16.3695 5.598 16.494 5.25 16.5H3.75V18H14.25V16.5H12.756C12.402 16.494 11.25 16.3695 11.25 15V12.4777C11.25 12.3142 11.2777 12.1567 11.3002 11.9985ZM15.375 6C15.9952 6 16.5 6.50475 16.5 7.125C16.5 8.661 15.2767 10.2937 11.907 10.4812C12.042 10.3065 12.1935 10.1475 12.3637 10.0117C13.6523 8.982 14.6318 7.4985 15.333 6H15.375ZM1.5 7.125C1.5 6.50475 2.00475 6 2.625 6H2.66775C3.369 7.4985 4.34775 8.982 5.63625 10.0117C5.8065 10.1475 5.95875 10.3065 6.093 10.4812C2.72325 10.2937 1.5 8.661 1.5 7.125ZM9.75 12.4777V15C9.75 15.6045 9.89325 16.0942 10.1153 16.5H7.8855C8.10675 16.0942 8.25075 15.6045 8.25075 15V12.4777C8.25075 11.0182 7.64025 9.69225 6.57375 8.8395C4.7265 7.36275 3.4545 4.53825 3.01425 2.3925C2.95425 2.1 3.08475 1.887 3.1665 1.785C3.315 1.6035 3.534 1.49925 3.76875 1.49925H14.2335C14.4675 1.49925 14.6865 1.6035 14.835 1.785C14.9175 1.887 15.048 2.10075 14.988 2.3925C14.5477 4.53675 13.2765 7.362 11.4285 8.8395C10.3628 9.6915 9.7515 11.0167 9.7515 12.4777H9.75ZM9.393 3L9.882 4.50375H11.4637L11.6887 5.19225L10.4092 6.12225L10.8982 7.62675L10.3125 8.052L9.03375 7.125L7.75125 8.05275L7.1655 7.6275L7.6545 6.123L6.375 5.193L6.6 4.5045H8.18025L8.66925 3.0045L9.393 3Z"
        fill="white"
      />
    </svg>
  );
};
