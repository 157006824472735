/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const NavigationBuilding021 = ({ className }) => {
  return (
    <svg
      className={`navigation-building-02-1 ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M2.66797 26.6666H5.33464M5.33464 26.6666H16.0013M5.33464 26.6666V9.60024C5.33464 8.10677 5.33464 7.35947 5.62528 6.78904C5.88095 6.28728 6.2886 5.87962 6.79036 5.62396C7.3608 5.33331 8.10809 5.33331 9.60156 5.33331H11.7349C13.2284 5.33331 13.9747 5.33331 14.5451 5.62396C15.0469 5.87962 15.4553 6.28728 15.7109 6.78904C16.0013 7.35892 16.0013 8.1053 16.0013 9.59586V13.6614M16.0013 26.6666H26.668M16.0013 26.6666V13.6614M26.668 26.6666H29.3346M26.668 26.6666V19.1575C26.668 18.4573 26.668 18.1066 26.584 17.7799C26.5095 17.4904 26.3865 17.2158 26.2209 16.9669C26.034 16.6861 25.774 16.4517 25.2529 15.9836L22.1862 13.2285C21.1782 12.3229 20.674 11.8703 20.1042 11.6985C19.6022 11.5473 19.0667 11.5473 18.5648 11.6985C17.995 11.8703 17.491 12.3231 16.4832 13.2285L16.0013 13.6614"
        stroke="#1A1A1C"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </svg>
  );
};
