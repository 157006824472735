import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const WarningShieldCheck = ({ className, warningShieldCheck = "/img/warning-shield-check.png" }) => {
  return <img className={`warning-shield-check ${className}`} alt="Warning shield check" src={warningShieldCheck} />;
};

WarningShieldCheck.propTypes = {
  warningShieldCheck: PropTypes.string,
};
