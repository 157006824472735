import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Trophy = ({ className, trophy = "/img/trophy.png" }) => {
  return <img className={`trophy ${className}`} alt="Trophy" src={trophy} />;
};

Trophy.propTypes = {
  trophy: PropTypes.string,
};
