import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Timer = ({
  size,
  theme,
  className,
  spanClassName,
  spanClassNameOverride,
  rectangleClassName,
  spanClassName1,
  spanClassName2,
  rectangleClassNameOverride,
  spanClassName3,
  spanClassName4,
  divClassName,
  spanClassName5,
  spanClassName6,
  text = "d",
  text1 = "15 ",
  text2 = "h",
  text3 = "51 ",
  text4 = "m",
  text5 = "44 ",
  text6 = "s",
}) => {
  return (
    <div className={`timer size-15-${size} theme-13-${theme} ${className}`}>
      <p className="element-d">
        <span className={`span ${spanClassName}`}>3 </span>
        <span className={`text-wrapper-10 ${spanClassNameOverride}`}>
          {text}
        </span>
      </p>
      <div className={`rectangle ${rectangleClassName}`} />
      <p className="element-h">
        <span className={`text-wrapper-11 ${spanClassName1}`}>{text1}</span>
        <span className={`text-wrapper-12 ${spanClassName2}`}>{text2}</span>
      </p>
      <div className={`rectangle-2 ${rectangleClassNameOverride}`} />
      <p className="element-m">
        <span className={`text-wrapper-13 ${spanClassName3}`}>{text3}</span>
        <span className={`text-wrapper-14 ${spanClassName4}`}>{text4}</span>
      </p>
      <div className={`rectangle-3 ${divClassName}`} />
      <p className="element-s">
        <span className={`text-wrapper-15 ${spanClassName5}`}>{text5}</span>
        <span className={`text-wrapper-16 ${spanClassName6}`}>{text6}</span>
      </p>
    </div>
  );
};

Timer.propTypes = {
  size: PropTypes.oneOf(["desktop", "mobile"]),
  theme: PropTypes.oneOf(["dark", "light"]),
  text: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
  text3: PropTypes.string,
  text4: PropTypes.string,
  text5: PropTypes.string,
  text6: PropTypes.string,
};
