{
  "main_heading": "INVERSIÓN INMOBILIARIA EN EL PARAÍSO, UNA OPORTUNIDAD ÚNICA",
  "header": {
    "project": "Proyecto",
    "benefits": "Beneficios",
    "properties": "Inmuebles",
    "riviera_maya": "Riviera maya",
    "videos": "Videos",
    "strengths": "Fortalezas",
    "team": "Equipo",
    "faq": "Faq",
    "pre_sale": "Pre-Venta"
  },
  "header_card": {
    "title1": "DISFRUTA LA VIDA EN TU VIVIENDA",
    "title2": "EN EL CARIBE, SIN COMPLICACIONES",
    "button": "DESCÚBRELO AHORA",
    "mobile_button": "ÚNETE AHORA"
  },
  "slider": {
    "title": "PRIMER PROYECTO (CARIBE)",
    "text1": "Bienvenido a una inversión inmobiliaria en la Riviera Maya (Playa del Carmen, Tulum, Cancún).",
    "text2": "Nuestras viviendas te ofrecen la libertad de disfrutar de un apartamento o casa sin preocupaciones ni costes de mantenimiento"
  },
  "welcome": {
    "title": "¿POR QUÉ NUESTRO MODELO DE INVERSIÓN INMOBILIARIA ES ÚNICO?",
    "subtitle": "¡Bienvenido a Rent Onchain, a la vanguardia de las inversiones inmobiliarias!",
    "text1": "En Rent Onchain, tu seguridad financiera es nuestra principal prioridad. Cada transacción en nuestra plataforma está respaldada por la tecnología Blockchain",
    "text2": "más avanzada y por contratos inteligentes, inmutables, auto-ejecutables y 100% seguros."
  },
  "form": {
    "name": "Nombre*",
    "email": "E-mail*",
    "phone": "Teléfono",
    "checkbox": "No soy un robot.",
    "checkbox2": "Pulse para continuar.",
    "button": "ÚNETE AHORA",
    "footer_button": "ENVIAR"
  },
  "benefits": {
    "title": "OBTENDRÁS LOS SIGUIENTES BENEFICIOS",
    "list": {
      "1": "Invierte desde 10.000€ y adquiere el derecho de vivir y disfrutar, de por vida, de una semana al año en una maravillosa vivienda en el Caribe.",
      "2": "Inversión con plusvalía",
      "3": " anual elevada.",
      "4": "Libertad total de elegir cuando ir al Caribe.",
      "5": "Podrás invertir en dólares, euros y/o crypto, tu decides!",
      "6": "Tu inversión estará registrada en la Blockchain",
      "7": ", por lo cual, será pública, encriptada, descentralizada y segura.",
      "8": "A partir del 3er año, podrás disfrutar de otros destinos además del Caribe (dispondremos de inmuebles en Europa, Caribe, Indonesia, USA, etc.)"
    }
  },
  "properties": {
    "title": "INMUEBLES",
    "subtitle": "DISPONIBILIDAD 01/01/24",
    "subtitle2": "DISPONIBILIDAD 12/31/24",
    "card": {
      "title_bronze": "BRONZE",
      "title_silver": "SILVER",
      "title_gold": "GOLD",
      "title_vip1": "VIP 1",
      "title_vip2": "VIP 2",
      "title_vip3": "VIP 3",
      "status": "Pre-Venta",
      "country": "País",
      "country_mexico": "México",
      "place": "Lugar",
      "place_riviera_maya": "Caribe (Riviera Maya)",
      "rooms": "Habitaciones",
      "sleeping_places": "Huéspedes",
      "type_of_property": "Tipo de inmueble",
      "type_of_property_apartment": "Apartamento",
      "type_of_property_loft": "Loft",
      "type_of_property_house": "Casa",
      "type_of_property_house_land": "Casa + terreno(> 600 m2)",
      "from": "de",
      "to": "a",
      "note": "*La fotografía puede no coincidir con el loft asignado pero, éste, tendrá las mismas características y estará publicado antes de la adquisición final.",
      "button": "MÁS INFORMACIÓN"
    }
  },
  "why_invest": {
    "title": "¿POR QUÉ INVERTIR AHORA EN LA RIVIERA MAYA?",
    "destination": "DESTINO",
    "destination_text": "México es el 6º país más visitado del mundo.",
    "trends": "TENDENCIAS",
    "trends_text": "En el año 2022 se incrementó un 68% la búsqueda online de inmuebles en la Riviera Maya.",
    "security": "SEGURIDAD",
    "security_text": "El estado de Quintana Roo es uno de los más seguros de México.",
    "capital_gain": "PLUSVALÍA",
    "capital_gain_text": "La plusvalía de los inmuebles se ha incrementado en estos últimos años un 25% anual.",
    "location": "UBICACIÓN",
    "location_text": "Excelente ubicación y conexiones diarias con las principales ciudades del mundo.",
    "climate": "CLIMA",
    "climate_text": "Clima tropical todo el año con una temperatura media de 27ºC.",
    "cosmopolitan": "COSMOPOLITA",
    "cosmopolitan_text": "Con más de 15.000 extranjeros viviendo en Quintana Roo.",
    "leisure": "OCIO",
    "leisure_text": "Vida nocturna vibrante, restaurantes gourmet, visitas turisticas y mucho más."
  },
  "join_revolution": {
    "title": "ÚNETE A LA REVOLUCIÓN DE LAS INVERSIONES INMOBILIARIAS",
    "subtitle": "¿Más información? Sé de los primeros en formar parte de esta revolución, únete a la pre-venta y descubre como invertir de manera inteligente"
  },
  "promo_video": {
    "title": "MIRA NUESTROS VIDEOS PROMOCIONALES",
    "subtitle": "Para conocer más sobre nuestra propuesta, mira nuestros videos promocionales."
  },
  "strengths": {
    "title": "FORTALEZAS DE NUESTRA EMPRESA",
    "experience": "EXPERIENCIA",
    "experience_text": "Somos expertos en diferentes áreas empresariales que nos hemos unido en un equipo integral para un éxito asegurado.",
    "diversity": "DIVERSIDAD",
    "diversity_text": "Equipo multidisciplinar de 11 países diferentes con amplia gama de habilidades y experiencia.",
    "creativity": "CREATIVIDAD",
    "creativity_text": "A la vanguardia de las nuevas tendencias, adecuándose a las nuevas tecnologías.",
    "scalability": "ESCALABILIDAD",
    "scalability_text": "Nuestro modelo de negocio es altamente escalable, nos permite, junto a nuestros inversores, expandirnos con facilidad a otros países.",
    "innovation": "INNOVACIÓN",
    "innovation_text": "Pensamos y analizamos nuevas fórmulas para solucionar problemas tradicionales de una forma más efectiva para un mundo globalizado.",
    "loyalty": "FIDELIZACIÓN",
    "loyalty_text": "Nuestro objetivo es una relación a largo plazo con nuestros clientes/inversores ofreciéndoles transparencia y un excelente servicio.",
    "alliances": "ALIANZAS",
    "alliances_text": "Creamos vínculos y alianzas con otras empresas del sector turístico y de hostelería para ofrecer la más alta gama y diversidad de servicios a nuestros clientes.",
    "liquidity": "LIQUIDEZ",
    "liquidity_text": "Gracias a nuestro marketplace, nuestro NFT de e-timesharing se puede ceder, traspasar, vender o alquilar, por lo que es una inversión muy flexible y atractiva."
  },
  "team": {
    "title": "NUESTRO EQUIPO",
    "developers": "DESARROLLO",
    "design": "DISEÑO",
    "operation": "OPERACIÓN",
    "marketing": "MARKETING",
    "compliance_development": "CUMPLIMIENTO DESARROLLO"
  },
  "faq": {
    "title": "PREGUNTAS FRECUENTES",
    "q1": "¿Cómo realizar la inversión?",
    "a1": "Invierte a partir de 10,000 € (sin ningún gasto adicional en toda la vida) y obtendrás el derecho de uso y disfrute de una semana al año de forma vitalicia en cualquiera de nuestras viviendas disponibles",
    "q2": "¿Qué garantías de rentabilidad ofrecen?",
    "a2": "Nuestro proyecto se basa en una sólida estrategia de crecimiento y rentabilidad teniendo en cuenta la plusvalía de la zona, el incremento natural del valor de los inmuebles y la libertad de poder ceder, traspasar, donar, vender y alquilar ese derecho adquirido para toda la vida",
    "q3": "¿Qué ocurre si quiero vender mi inversión?",
    "a3": "Seríamos nosotros los primeros interesados en poder adquirir tu derecho pero, también, podrás venderlo a quien tu quieras o utilizar nuestro marketplace u otros similares donde seguro que encontrarás alguien interesado",
    "q4": "Si un año no puedo o no quiero ir, ¿qué puedo hacer?",
    "a4": "Puedes cederlo/regalarlo para que alguien querido lo pueda disfrutar o, si quieres obtener un beneficio y no preocuparte de nada, nosotros nos encargamos de alquilarlo para tí en nuestro marketplace",
    "q5": "¿Qué tratamiento fiscal tiene mi derecho de uso y disfrute?",
    "a5": "Depende de cada país, nosotros podemos asesorarte personalmente",
    "q6": "¿Es necesario que viaje a México para algún trámite?",
    "a6": "NO. Todo se hará vía onlinee",
    "q7": "¿Cuándo puedo empezar a disfrutar de mi sueño?",
    "a7": "A partir del segundo mes de haber efectuado el ingreso",
    "q8": "¿Puedo llevar a mi mascota a mi vivienda?",
    "a8": "Por supuesto, aunque ten en cuenta que cada vivienda podría tener alguna restricción al respecto, revisa, por favor, las condiciones de uso y disfrute",
    "q9": "¿Qué medidas de seguridad tiene el complejo?",
    "a9": "Hay seguridad privada fija en la entrada y de ronda por todo el perímetro",
    "q10": "¿Qué garantía tengo de que mi inversión está en buenas manos?",
    "a10": "Todas las transacciones quedarán registradas en la blockchain de forma descentralizada por lo que será de dominio público e inmutable y con tu información personal encriptada",
    "q11": "¿Puedo dar en herencia mi derecho adquirido?",
    "a11": "Si, Tu derecho sobre la vivienda está ligado a una clave única, que como sabes, está protegida en la blockchain, llegado el momento, nosotros te asesoraremos"
  },
  "pre_sale": {
    "title": "¡LA REALIZACIÓN DE TU SUEÑO EMPIEZA AQUÍ!",
    "subtitle": "La Venta de NFT's de Alquiler vacacional empieza el 1 de Julio de 2024:",
    "content_subtitle": "Durante la PRE-VENTA queremos recompensar tu confianza en nosotros y por eso te ofrecemos ganar una semana gratis:",
    "content_text": "Si adquieres los NFT's antes del 1 de julio de 2024, además del ahorro por el precio de pre-lanzamiento, por cada 10 NFT's que adquieras (los tuyos más los que recomiendes), te regalamos otro NFT de una semana, con los mismos derechos y condiciones.",
    "caption": "Con Rent Onchain harás una inversión eco-sos-teligente porque permite el aprovechamiento total de la vivienda."
  },
  "footer": {
    "contact": {
      "title": "CONTACTA",
      "office": "Oficina",
      "address": "Plaza Can Bassa, 1 08402 Granollers (Barcelona) España",
      "social_networks": "Redes sociales",
      "telegram": "Telegram",
      "instagram": "Instagram",
      "email": "E-mail",
      "email_text": "sales@rentoncha.in"
    },
    "menu": {
      "title": "MENÚ",
      "project": "Proyecto",
      "benefits": "Beneficios",
      "properties": "Inmuebles",
      "riviera_maya": "Riviera Maya",
      "videos": "Videos",
      "strengths": "Fortalezas",
      "team": "Equipo",
      "faq": "FAQ",
      "pre_sale": "Pre-Venta"
    },
    "form_title": "SI TE INTERESA RECIBIR MÁS INFORMACIÓN SOBRE RENT ONCHAIN, APÚNTANTE A NUESTRO BOLETÍN INFORMATIVO Y SÉ EL PRIMERO EN CONOCER NUESTRAS ÚLTIMAS ACTUALIZACIONES Y OFERTAS ESPECIALES",
    "terms": "Términos y condiciones",
    "privacy_policy": "Política de privacidad",
    "cookie_policy": "Política de cookies"
  },
  "msg": {
    "success_send": "SU SOLICITUD SE HA ENVIADO CORRECTAMENTE",
    "accept": "ACEPTAR"
  }
}
