/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const InterfaceHeart012 = ({ className }) => {
  return (
    <svg
      className={`interface-heart-01-2 ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M12 7.69428C10 2.99984 3 3.49984 3 9.49987C3 15.4999 12 20.5001 12 20.5001C12 20.5001 21 15.4999 21 9.49987C21 3.49984 14 2.99984 12 7.69428Z"
        stroke="#6957DA"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};
